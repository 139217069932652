import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate  } from 'react-router-dom';
import { faTrashAlt, faRefresh, faAdd, faDownload }  from '@fortawesome/pro-regular-svg-icons';
import { strings } from "../../services/Localization";
import { checkLogin } from "../../services/Login";
import AppContext from '../../context/AppContext'
import { dialog, dialogDescription} from '../../components/Common';
import { TAB_ADD_RESOURCE, TAB_EDIT_RESOURCE } from '../../datatypes/tabsconstants'
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "../../utils/Toaster";
import PageContent from "../PageContent";
import { getAppBase } from '../../utils/consts';
import { IUser } from "../../dassTypes";
import { ActionType, BulkActionType, ColumnType, DataTableOption } from "../../components/Common/DataTable/DataTypes";
import{ ID_INPUT_VALIDATION, DEFAULT_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";
import { getVisibleActions, dateTimeString, actionIcon, elipsis } from '../../utils/filters';
import RenderTagsOverlay from "../../components/Common/RenderTagsOverlay";
import { trustedFormatText } from "../../schemaengine/client/SchemaTextParser";

const ResourceType = {
    json:       ["Json", "text-bg-blue"],
    bin:        ["Binary file", "text-bg-darkorange"],
    image:      ["Image", "text-bg-green" ],
    code:       ["Code", "text-bg-yellow"], 
    data_model: ["Data Model", "text-bg-red"]
}

const ScanStatus = {
    not_scanned: ["Not Scanned", "text-bg-blue"],
    pending:     ["Pending", "text-bg-darkorange"],
    in_progress: ["In Progress", "text-bg-green" ],
    scanned:     ["Scanned", "text-bg-yellow"], 
    quarantined: ["Quarantined", "text-bg-red"]
}


type filterOptionType = {
    label:string;
    value:string;
}

function filterTypeOptions(): filterOptionType[]  {
    return [
        { label: "JSON",   value: "json" },
        { label: "Binary file",   value: "bin" },
        { label: "Code",   value: "code" },
        { label: "Image",   value: "image" },
        { label: "Data Model",   value: "data_model" }
    ];
}

function scanStatusFilterTypeOptions(): filterOptionType[]  {
    return [
        { label: "Not Scanned",   value: "not_scanned" },
        { label: "Pending",   value: "pending" },
        { label: "In Progress",   value: "in_progress" },
        { label: "Scanned",   value: "scanned" },
        { label: "Quarantined",   value: "quarantined" }
    ];
}

export interface IRowType {
    useridx: string;
    resource_uuid: string;
    resource_name: string;
    resource_type: string;
    creation_time: string;
    scan_status?: string;
    format?: {
        mime?: string;
        filename?: string;
        file_size?: string
    };
    file_size: number;
    resources?: any;
    description: string;
    groups?: string;
    tags?: string
}

interface IResourcesStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    pageTitle: string;
    editEntityId: string | null;            // The entity in this view is a gateway
    schemaMethod: "post" |"put" | "" | string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
    isRowDeleted?:boolean;
}

interface IResourcesProps {
}




const Resources: React.FC<IResourcesProps> = (props) =>  {

    const { id, tabname } = useParams();
    const navigate = useNavigate();
    const AppContextObj = useContext(AppContext);

    const stateInit: IResourcesStates = {
        loggedUser: AppContextObj.user,
        showAlertModal: false,
        pageTitle: strings.RESOURCE_TITLE, // Fixme
        editEntityId: '',
        schemaMethod: "",
        breadCrumbArr: [{label: strings.NAV_RESOURCE_ITEM, url:''}],
        refresh:false,
        isRowDeleted:false
    };

    const [state, setState] = useState<IResourcesStates>(stateInit)

    useEffect(() => {

        if (checkLogin(AppContextObj.user)) {
            setState(prevState => { 
                return {...prevState, loggedUser: AppContextObj.user }
            })
        }

    },[])


    const detailPageNav = async ( navigate, tab, id, row) => {
        if (id) {
            const data = await GenericDassQuery(`/rest/resources_storage/create-token/${row.resource_uuid}`)
            row['token'] = data.data.token
            if (row.json_schema_uuid) {
                const data1 = await GenericDassQuery(`/rest/resources_storage/create-token/${row.json_schema_uuid}`)
                row['json_uuid_token'] = data1.data.token
            }
            navigate(`${getAppBase()}/resource/${id}/${tab}`, {state: {row: row, prevPageUrl: `${getAppBase()}/resource`}})
        }
    }

    const detailPageAdd = ( navigate, tab) => {
        
        navigate(`${getAppBase()}/resource/${tab}`, {state: {tab: tab, prevPageUrl: `${getAppBase()}/resource`}})
    }

    const downloadResource = async (data: IRowType, fileName: string) => {
        try {
            const token = await GenericDassQuery(`/rest/resources_storage/create-token/${data.resource_uuid}`, { method: "GET" });
            const URL = "/uiapi/rest/resources_data/" + token.data.token;
            const link = document.createElement('a');
            link.href = URL;
            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(URL);
        } catch (e) {
            toast.error(e.message);
        }
    };




    const deleteResource = async ( row: IRowType ) => {

        const confirmDialogeSettings = {
            title:  "You are about to delete a resource",
            description: "Delete '" + row.resource_name + "'?",
            actionLabel: 'Delete',
        };

        if (await dialog(confirmDialogeSettings) === true) {
            try {
                await GenericDassQuery("/rest/resources_storage/" + row.resource_uuid, {
                    method: "DELETE",
                });
                toast.success(`Resource ${row.resource_name} successfully deleted`);
            } catch (e) {
                toast.error(e.message);
            }
            refreshTable(true);
        }
    }


    const deleteResources = async (selectedRows: IRowType[]) => {
        
        const resourceids = selectedRows.map((row) => {
            return row['resource_uuid'];
        })
        const resources_name = selectedRows.map((row) => {
            return row['resource_name'];
        })

        if (resourceids && resources_name.length > 0) {
            const confirmDialogeSettings = {
                title:  `You are about to delete ${resourceids.length} resource`,
                description: await dialogDescription(resources_name),
                actionLabel: 'Delete',
            };
    
            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(resourceids.map((resourceid) => {
                        return GenericDassQuery("/rest/resources_storage/" + resourceid , { method: "DELETE" })
                    })).then((values) => {
                        refreshTable(true);
                        toast.success("Resources successfully deleted");
                    });
                } catch (e) {
                    toast.error(e.message);
                }                
            }
        }
    }

    const  getActions = () => {

        const actions: ActionType<IRowType>[] = [
            {
                type: "action",
                text: strings.DOWNLOAD_RESOURCE,
                render: (row, title) => actionIcon(row.resource_uuid, title, faDownload.iconName),
                action: (row: IRowType) => { downloadResource(row, row.resource_uuid) }
            },
            {
                type: "action",
                text: strings.RESOURCE_DELETE,
                visible: () => { return AppContextObj.user && AppContextObj.user.can_delete_resources && !AppContextObj.user._readonly },
                render: (row, title) => actionIcon(row.resource_uuid, title, faTrashAlt.iconName),
                action: (row: IRowType) => deleteResource(row),
            }
        ];
        const bulkActions: BulkActionType<IRowType>[] = [
            {
                type: "action",
                text: strings.RESOURCES_DELETE,
                visible: () => { return AppContextObj.user && AppContextObj.user.can_delete_resources && !AppContextObj.user._readonly },
                render: (row, title) => actionIcon(row.resource_uuid, title, faTrashAlt.iconName),
                action: (selectedRows) => {  deleteResources(selectedRows) },
            }
        ];

        return { actions, bulkActions };
    }



    const  initDataTable = () => {

        const appBase = getAppBase();
        const optionFetcher = async () => {
            try {

                let pageUrl = `/rest/tags`;
                let response  = await GenericDassQuery(pageUrl, { method: "GET" });
                const tags =  response.data;
                let tagOptions = tags.map((tag) => {
                    return {label: tag.tagid, value: tag.tagid}
                })
                return tagOptions;

            } catch(e) {
                console.log(e);
            }
        }

        const { actions, bulkActions } = getActions();
        
        let columns: ColumnType<IRowType>[] = [
            {
                key: "resource_uuid",
                type: "text",
                title: strings.UUID,
                inputValidation: ID_INPUT_VALIDATION,
                filterable: true,
                filterField: 'search_uuid',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                cellWidth: 30,
                cellWidthType: "%",
                render: (row) => (row.resource_uuid ? trustedFormatText(`[[fa-ellipsis]]${row.resource_uuid.slice(-6)}`, row.resource_uuid, null, null) : null),
                render_tooltip: (row) => row.resource_uuid,
                copyLink: true,
                dataAlign: "center",
                customClass: 'font-monospace fa-80',
                newCellWidth: "10%"
            },
            {
                key: "resource_name",
                type: "text",
                title: "Name",
                filterable: true,
                filterField: 'search_name',
                filterType: 'text',
                newCellWidth: "24%",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                filterParams: {
                    mapper: (x) => x || undefined
                },
                detailLink:true,
                detailPageNav: (row: IRowType) => detailPageNav(navigate, TAB_EDIT_RESOURCE, row.resource_uuid, row),
                cellWidth: 30,
                render:(row) => elipsis(row.resource_name, 30),
                render_tooltip: (row) => row.resource_name,
            },
            {
                key: "description",
                type: "text",
                title: strings.DESCRIPTION,
                inputValidation: ID_INPUT_VALIDATION,
                filterable: true,
                filterField: 'search_description',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                cellWidth: 30,
                cellWidthType: "%",
                newCellWidth: "24%",
                render: (row) => elipsis(row.description, 40),
                render_tooltip: (row) => row.description,
                
            },
            {
                key: "creation_time",
                type: "text",
                title: "Creation Timestamp",
                inputValidation: ID_INPUT_VALIDATION,
                filterType: 'text',
                render: x => dateTimeString(x.creation_time),
                sortable: true,
                sortKey: "sort_by_creation_time",
                defaultSortOrder:'desc',
                cellWidth: 20,
                cellWidthType: "%",
                newCellWidth: "12%",
                customClass: 'font-monospace fa-80'
                
            },
            
            {
                key: "tags",
                title: "Tags", // FIXME Add string.xx
                type: "text_with_tooltip",
                cellWidth: 25,
                cellWidthType: '%',
                dataAlign: "left",
                render: x => { return RenderTagsOverlay(x.tags, "gray", `${appBase}/tags/~tag~/edit-tag`, { prevPageUrl:`${getAppBase()}/resource`, row: { tagid:0 },}, "Tags") },
                customNavigation: (row) => {
                    if(row.tags){ return null } 
                    else { detailPageNav(navigate, TAB_EDIT_RESOURCE, row.resource_uuid, row);}
                },
                filterable: true,
                filterField: "search_tags",
                filterType: "multiselect",                
                newCellWidth: "8%",
                filterParams: {
                    optionFetcher: optionFetcher,
                    data: [],
                    mapper: x => x,
                },  

                // extraClass: 'ellipsis-class',
            },
            {
                key: "file_size",
                title: "Size (MB)", // FIXME Add string.xx
                type: "number",
                cellWidth: 25,
                cellWidthType: '%',
                dataAlign: "center",
                render: (row:IRowType) => ((row.file_size / 1000000 < 0.01 ? (row.file_size / 1000000).toFixed(4) + "" : (row.file_size / 1000000).toFixed(2) + "")),   
                newCellWidth: "5%",
                customClass: 'font-monospace fa-80',

            },
            {
                key: "resource_type",
                type: "text",
                title: "Type",
                render: (row:IRowType) =>  <span className={`badge`}> {ResourceType[row.resource_type]?.[0] ?? ""}</span>,
                cellWidth: 80,
                dataAlign: "center",
                cellWidthType: "%",
                filterable: true,
                filterField: 'search_type',
                filterType: 'multiselect',
                filterParams: {
                    mapper: (x) => x,
                    optionFetcher: filterTypeOptions
                },
                newCellWidth: "10%",
                
            },
            {
                key: "scan_status",
                type: "text",
                title: "Status",
                render: (row:IRowType) =>  <span className={`badge`}> {ScanStatus[row.scan_status]?.[0] ?? ""}</span>,
                cellWidth: 80,
                dataAlign: "center",
                cellWidthType: "%",
                filterable: true,
                filterField: 'search_scan_status',
                filterType: 'multiselect',
                filterParams: {
                    mapper: (x) => x,
                    optionFetcher: scanStatusFilterTypeOptions
                },
                newCellWidth: "10%",
                
            }
        ];

        let options: DataTableOption = {
            url:'/uiapi/rest/resources_storage',
            query_param: { all:true, get_pages:true, limit:DEFAULT_RECORD_LIMIT, stream:'progress', sort_by_creation_time: "desc"}, // TODO: if optional parameters are not provided here, then where?
            serial_number: false,
            id_field: 'resource_uuid',
            oboe_path: 'pages.*',
            available_key: 'resource_uuid',
            emptyDataMsg: strings.NO_RESOURCE_AVAILABLE,
            columns,
            modal:false,
            actions: actions,
            bulkActions: getVisibleActions(bulkActions)
        }

        return options;
	}

    const refreshTable = (isRowDeleted=false) => {
        setState(prevState => {
            return {...prevState, refresh:!prevState.refresh,isRowDeleted:isRowDeleted}
        })
    }


    const  getPageButtons = () => {

        var  pageButtons: PageButtonType[] = [
            {
                title: "Resource",
                action: () => detailPageAdd(navigate, TAB_ADD_RESOURCE),
                type: 'button_with_icon',
                icon: faAdd,
                visible: () => { return AppContextObj.user && AppContextObj.user.can_create_resources && !AppContextObj.user._readonly }
                
            },
            {
                title: strings.REFRESH,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh,
            },
        ]

        return pageButtons;
    }

    
    return (<PageContent
        name={`resources`} 
        id={id} 
        tabname={tabname} 
        actions={getActions()} 
        breadCrumbArr={state.breadCrumbArr} 
        pageButtons={getPageButtons()} 
        isRowDeleted={state.isRowDeleted}
        countLabel={`Resources`} 
        dataTableOption={initDataTable()} 
        refresh={state.refresh}>
    </PageContent>)

}

export default Resources;