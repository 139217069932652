/**
 * Schema Resources are used to load and PUT/POST data
 */

import { IJsonSchemaObject, IResourceResponseObject, IUiSchema, IUiSchemaDataResource } from "../UiJsonSchemaTypes";
import { IListener, IUiSchemaSetValueResult, Self, trustExpr, updateValues } from "./SchemaController";
import { evalExpr, evalString, getObjectValues, getPathAndKey } from "./SchemaTools";




/**
 * call this function whenever the schema has been updated. It will parse the schema and extract the resource requests
 * 
 * @param self 
 * @param props 
 * @param jsonSchema 
 * @returns 
 */

export function handleNewSchemaResources(self: Self, jsonSchema: IJsonSchemaObject): IListener[] {

	// schema is changed. We need to update the listeners
	const uiSchema: IUiSchema = jsonSchema.$uiSchema || {};
	const listeners: IListener[] = [];

	// First clear all existing (in any) interval timers
	for (const it of self.intervalTimers) {
		clearInterval(it);
	}
	self.intervalTimers = [];

	for (const dataResource of uiSchema.dataResources || []) {
		const id = dataResource.resourceId || "__default__";

		// For periodic triggers, create the interval timer and push the handle to the intervalTriggers array
		// so we can clear the timers again later when we close the view.
		if (dataResource.triggerPeriodicallyEveryNumSeconds) {
			self.intervalTimers.push(setInterval(() => {
				handleResource(self, dataResource);
			}, dataResource.triggerPeriodicallyEveryNumSeconds * 1000));
		}

		// setup trigger
		let firstTriggerOnValue = false;
		if (dataResource.triggerOnValuesChange) {
			const { keypath } = getPathAndKey(dataResource.targetKey || "");
			const path = keypath ? keypath + "/" : "";
			const watchKeys = dataResource.triggerOnValuesChange.map(fk => fk.startsWith("/") ? fk : path + fk);

			listeners.push({
				keys: watchKeys,
				handle: () => { handleResource(self, dataResource) },
			});
			for (const key of watchKeys) {
				if (self.objects.values[key] != null) {
					firstTriggerOnValue = true;
				}
			}
		}
		if (dataResource.triggerOnSetTabs) {
			listeners.push({
				tabs: dataResource.triggerOnSetTabs,
				handle: () => { handleResource(self, dataResource) },
			});
			if (dataResource.triggerOnSetTabs.includes(self.objects.control.activeTab)) {
				firstTriggerOnValue = true;
			}
		}

		// exec immediately for onOpen handlers.
		if (!self.resourceStates[id]?.initialLoad &&
			(dataResource.triggerOnOpen || (dataResource.triggerOnOpenOnLoadRequest && self.props.loadDataOnOpen) || firstTriggerOnValue)) {
			handleResource(self, dataResource);
			if (id !== "__default__") {
				self.resourceStates[id] = { ...self.resourceStates[id], initialLoad: true }			
			}
		}
	}

	// Finally set the __default__ so we don't do initial update again for any resource without id.
	self.resourceStates["__default__"] = { ...self.resourceStates["__default__"], initialLoad: true }

	return listeners;
}






/**
 * Handle a resource when it has been triggered
 * 
 * @param self 
 * @param dataResource 
 * @returns 
 */

export async function handleResource(self: Self, dataResource: IUiSchemaDataResource) {

	try {
		self.busyWithResources++;
		updateValues(self, { ioOngoing: true }, "", "");

		const lib = self.lib;
		const control = self.objects.control;
		
		const oldValues: any = getObjectValues(self.objects.rootCondJsonSchema, self.instantOldValues, 
									{ treatNullAsUndefined: true, useDefaults: false, additionalProperties: true,
										contOnError: false, skipOnNullType: false }, lib, self.objects);
		const newValues: any = getObjectValues(self.objects.rootCondJsonSchema, self.instantValues,
									{ treatNullAsUndefined: true, useDefaults: true, additionalProperties: true,
										contOnError: false, skipOnNullType: false }, lib, self.objects);

		const diffValues: any = {};
		for (const key of Object.keys(newValues)) {
			if (newValues[key] !== oldValues[key]) {
				diffValues[key] = newValues[key];
			}
		}

		self.log("objects", self.objects);
		self.log("old values", oldValues);
		self.log("new values", newValues);
		self.log("diff values", diffValues);

		const { key, keypath } = getPathAndKey(dataResource.targetKey || "");

		let objects = { ...self.objects, newValues, diffValues,
						values: keypath ? self.objects.values[keypath + "?"] : self.objects.values,
						errors: keypath ? self.objects.errors[keypath + "?"] : self.objects.errors };

		let readOnly = !!(control.modalReadOnly || control.readOnly);

		if (dataResource.confirmMessage) {
			const msgStr = evalString(dataResource.trust, dataResource.confirmMessage, lib, objects, { readOnly });
			if (msgStr  &&  !await self.props.showMessage("confirm", msgStr + "")) {
				if (--self.busyWithResources === 0) { updateValues(self, { ioOngoing: false }, "", ""); }
				return true;
			}
		}

		let headers: any = undefined;
		if (dataResource.headers)     { headers = {...headers, ...dataResource.headers}; }
		if (dataResource.contentType) { headers = {...headers, "Content-Type": dataResource.contentType }; }

		const body = dataResource.body ? evalString(dataResource.trust, dataResource.body, lib, objects, { readOnly }) : null;
		const url = evalString(dataResource.trust, dataResource.url, lib, objects, { readOnly });

		self.log("Resource", dataResource.url, url, headers, body);

		const responseObject: IResourceResponseObject = {} as any;
		const chunkCallback = async (value: string) => {

			if (dataResource.setValue) {	
				const objects = { ...self.objects, newValues, diffValues,
								  values: keypath ? self.objects.values[keypath + "?"] : self.objects.values,
								  errors: keypath ? self.objects.errors[keypath + "?"] : self.objects.errors };
				const targetObj = evalExpr(trustExpr(dataResource.trust), dataResource.setValue, lib, objects, { fullkey: dataResource.targetKey, readOnly, value, status, responseObject }, null);
				updateValues(self, targetObj, keypath, key);
			}
		};

		let data: { ok: boolean, status?: number, data: any } = { ok: true, data: null };

		if (url && typeof url === "string") {

			// If a resource ID is provided set a variable in the scope __resourceid__xx_started = epoc. This can be used to make conditional
			// statements in the schema to show e.g. cancel buttons, or ghost the trigger button, etc.
			//
			if (dataResource.resourceId) {
				updateValues(self, { values: { ["__resourceid_" + dataResource.resourceId + "_started"]: Date.now() } }, keypath, key);
			}

			// We encapsulate the IO request in a local try/catch to be sure that if an exception happen, we complete and clear the
			// resource variable again.
			let exception: any = null;
			try {
				data = await self.props.getResources((dataResource.method || "get").toUpperCase(), url,
						{ headers, body, responseObject, chunkCallback,
							addAbortHandler: (handler) => self.abortHandlers.push({ handler, id: dataResource.resourceId || "" }),
							removeAbortHandler: self.removeAbortHandler })

			} catch (e) {
				exception = e;
			}

			if (dataResource.resourceId && data.status !== -1) {
				updateValues(self, { values: { ["__resourceid_" + dataResource.resourceId + "_started"]: 0 } }, keypath, key);
			}

			if (exception) { throw exception; }
		}

		self.log("Resource result", url, data);

		if (!data.ok && data.status === -1) {
			// Got abort, just exit without further fuss
			if (--self.busyWithResources === 0) { updateValues(self, { ioOngoing: false }, "", ""); }
			return false;
		}


		// Update values again after the await
		objects = { ...self.objects, newValues, diffValues,
					values: keypath ? self.objects.values[keypath + "?"] : self.objects.values,
					errors: keypath ? self.objects.errors[keypath + "?"] : self.objects.errors };
		readOnly = !!(control.modalReadOnly || control.readOnly);

		const value = data.data;
		const status = data.status;
		const lang = self.props.lang || "";
		let evtxt = "";

		// Depending if the call returns success or error, we evaluate one for the showMessageOnSuccess or showMessageOnError
		// These string are parsed with evalString and can therefor contain expressions. The normal returned value is a string,
		// however it is also possible to return an object with the following:
		//    { type: "success" | "error", message: string }
		// This way it is possible to popup an error dialog on an success and vice versa.
		// An empty return value ("" or null) will not show any message

		if (data.ok && dataResource.showMessageOnSuccess) {
			evtxt = ((lang && (dataResource as any)["showMessageOnSuccess[" + lang + "]"]) || dataResource.showMessageOnSuccess);
		}
		if (!data.ok && dataResource.showMessageOnError !== "") {
			evtxt = ((lang && (dataResource as any)["showMessageOnError[" + lang + "]"]) || dataResource.showMessageOnError) || "{{value}}";
		}
		if (evtxt) {
			const msgtxt: any = evalString(dataResource.trust, evtxt, lib, objects, { value, status, readOnly }) as string;
			if (msgtxt) {
				const type: any   = (typeof msgtxt === "object" && msgtxt.type)    ? msgtxt.type    : (data.ok ? "success": "error");
				const msg: string = (typeof msgtxt === "object" && msgtxt.message) ? msgtxt.message : msgtxt as string;
				msg && self.props.showMessage(type, msg);
			}
		}


		let continueOnError = false;
		if (!data.ok && dataResource.continueOnError != null) {
			continueOnError = typeof dataResource.continueOnError === "string" 
						? evalExpr(trustExpr(dataResource.trust), dataResource.continueOnError, lib, objects, { fullkey: dataResource.targetKey, readOnly, value, status, responseObject }, Error)
						: dataResource.continueOnError;
		}

		let targetObj: IUiSchemaSetValueResult = {};
		if (data.ok || continueOnError) {
			targetObj = dataResource.setValue
					? evalExpr(trustExpr(dataResource.trust), dataResource.setValue, lib, objects, { fullkey: dataResource.targetKey, readOnly, value, status, responseObject }, Error)
					: { value };
			self.log("setValues", dataResource.setValue, targetObj);
		}

		// If the IO fail we abort the apply
		// TODO: allow more control on this.
		if (!data.ok && !continueOnError) { targetObj = { apply: false, ...targetObj }; }

		if (--self.busyWithResources === 0) { targetObj.ioOngoing = false; }
		updateValues(self, targetObj, keypath, key);

		return data.ok;

	} catch (e) {
		self.log(e);
		if (--self.busyWithResources === 0) { updateValues(self, { ioOngoing: false }, "", ""); }
		return false;
	}
}



