import React, { useState } from 'react';
import { DateRangePicker, Range } from 'react-date-range';
import {    
    Modal, 
    Button,
    
} from 'react-bootstrap';

 import { 
     //addDays , 
     format} from 'date-fns';


interface DateRangeRenderState {
    show:boolean;
    emptyDate: Range[];
    selectedDate:Range[];
    dateRangeStr:string;
}

interface PayloadExportModalProps {
    handleClose: () => void;
    show: boolean;
    DataTableContextObj?: any;
}



const PayloadExportModal: React.FC<PayloadExportModalProps> = ( props ) => {

    const emptyDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }
    
    const initState = {
            show:false,
            emptyDate: [emptyDate],
            selectedDate:[emptyDate],
            dateRangeStr: ''
    }

    const [state, setState] = useState<DateRangeRenderState>(initState);

    //let urlValue = getUrlValue(DataTableContextObj, column.filterField);
 
    //Sun May 08 2022 00:13:10 GMT+0530 (India Standard Time)
    //Sun May 0 0000 00:00:00 GMT+0530 (India Standard Time)
    

      const setCalenderToggle = () => {

            props.handleClose()
      }



      /**
       * endDate: Tue May 24 2022 00:00:00 GMT+0530 (India Standard Time) {}
        key: "selection"
        startDate: Tue May 24 2022 00:00:00 GMT+0530 (India Standard Time) {}
        [[Prototype]]: Object
       * 
      */
    const setSelectedDateHandler = ( item ) => {
            
            
            setState(prevState => { 
                return {...prevState, selectedDate: item}
            });

            // const setCalenderToggle = () => {
            //     setState(prevState => { 
            //         return {...prevState, selectedDate: item}
            //     });
            // }
           
    }

    const applySearch = () => {
        
        //https://cto-playground.dev.orbiwise.com/uiapi/rest/export/payloads?download_filename=payloads.csv&tz=-330&payload_from_date=2022-06-14T18:30:00.000Z&payload_to_date=2022-07-25T18:29:59.999Z
        
        let startDate = state.selectedDate[0].startDate;

        let endDate = state.selectedDate[0].endDate;
        //2022-06-14T18:30:00.000Z
        let startDateFmt = format(startDate, 'yyyy-MM-dd');

        let endDateFmt =  format(endDate, 'yyyy-MM-dd');
        // Get today's date in the same format
        // set start/end time to 00:00:00.000 and 23:59:59.999 if the selected date range is today
        if( startDateFmt === endDateFmt) {
            startDate.setHours(0, 0, 0, 0);  // Set time to 00:00:00.000 of the day
            startDateFmt = startDate.toISOString();
            endDate.setHours(23, 59, 59, 999);  // Set time to 23:59:59.999 of the day
            endDateFmt = endDate.toISOString();
        }

        var queryStr = '';
    
        queryStr += '&payload_from_date=';
        queryStr += startDateFmt;

        queryStr += '&payload_to_date=';
        queryStr += endDateFmt;
    
        // if there are selected rows then add them to the query string, let's use searchState.selectedRowIds[].deviceUuid
        let searchState = props.DataTableContextObj.searchState;

        if (searchState?.selectedRowIds?.length > 0) {
            const selectedRowIds = searchState.selectedRowIds
                .map(item => item.deviceUuid ?? item.deveui)
                .filter(Boolean);
        
            const queryKey = searchState.selectedRowIds.some(item => item.deviceUuid) ? 'deviceuuids' : 'deveui';
            queryStr += `&${queryKey}=${selectedRowIds.join(',')}`;
        }

        var url = window.location.protocol;
        var tz = "&tz=" + (new Date()).getTimezoneOffset();
        url += '//';
        url += window.location.host;
        let downloadUrl = url + '/uiapi/rest/export/payloads?download_filename=payloads.csv' + tz + queryStr;
        
        window.location.assign(downloadUrl);
        //close popup after 1 sec
        setTimeout(() => {
            props.handleClose();
        },1000)
        

    }

    const clearSearch = () => {
        
        setState(prevState => {
            return {...prevState, show:!state.show, selectedDate:[emptyDate], dateRangeStr:''}
        });

    }

    
    //from_date=2021-12-16T00:00:00%2B05:30
    //2021-12-27 12:52:00 GMT+5:30 - 2021-12-30 12:52:00 GMT+5:30     

    ///format(new Date(2014, 1, 11), 'yyyy-MM-dd h:I:SS Z');
    //const formatPattern = 'yyyy-MM-dd h:I:SS z';
    let display = (props.show) ? '' : 'none';

    const DateRangePickerAny: any = DateRangePicker;        // FIXME:

      return (
            <div style={{display: display}}>
                <input type="text" 
                                className='form-control form-control-sm'
                                onClick={setCalenderToggle} 
                                onChange={ (e) => {  } }
                                value={state.dateRangeStr}  
                                placeholder={`Search Date`} />
                <Modal
                        show={props.show}
                        onHide={props.handleClose}
                        size="xl"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Export Payloads
                            </Modal.Title>
                        </Modal.Header>
                            <Modal.Body>
                                <small>You are about to export payloads for all devices which may result in a very large download. Please limit the export to the relevant time range.</small>

                                <DateRangePickerAny
                                    onChange={item => setSelectedDateHandler([item.selection])}
                                    moveRangeOnFirstSelection={true}
                                    editableDateInputs={false}
                                    months={2}
                                    direction="horizontal"
                                    ranges={state.selectedDate}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="dark" onClick={applySearch}>Download</Button>
                                <Button variant="outline-dark" onClick={clearSearch}>Clear</Button>
                                <Button variant="outline-dark"  onClick={setCalenderToggle}>Close</Button>
                            </Modal.Footer>
                        </Modal>
            </div>
        );
     
}

export default PayloadExportModal;