/**
 * Vega-Like tools add support for transformation, parameters, and value selection in the dashboard schemas.
 * It's not Vega, it is Vega-Like
 */

import { codegenExpression, parseExpression, constants as vegaConstants, functions as vegaFunctions } from "vega-expression";



export interface ValueRef {
	field: string;
	datum: number;
	expr: string;
}

export type Value = number | ValueRef


export interface VegaLikeSchemaBase {
	$schema: string;
    debug: Value;
    data: {
		values: any[];
	};
	transform: Array<{
		calculate: string;
		as: string;

		filter: string;

		pivot: string;
		value: string;
		groupby: string[],
		limit: number;
		op: string;

	}>;
	params: Array<{
		name: string;
		value: number | string;
        expr: string;
	}>;


    // internal
    __globobj__: any;

}




export function vegaLikeGetInfo(obj: any) {

    let   sch = obj as VegaLikeSchemaBase;
    const isSchema = typeof sch === "object" && sch != null && typeof sch.$schema === "string";

    const debug = !!(isSchema && sch.debug);
    return { isSchema, debug };
}


/**
 * 
 * @param obj 
 * @returns 
 */

export function vegaLikeGetSchema<T>(obj: any): T {

    let   schema = obj as T;
    let   sch = obj as VegaLikeSchemaBase;
    const isSchema = typeof sch === "object" && sch != null && typeof sch.$schema === "string";

    if (!isSchema) {
        return null;
    }

    const __globobj__ = {};
    let values = vegaLikeTransforms(sch.data?.values, sch.transform);
    schema = { ...schema, data: { ...sch.data, values }, __globobj__};


    if (Array.isArray(sch.params)) {
        for (const param of sch.params) {
            if (param.value !== undefined) {
                __globobj__[param.name] = param.value;
            }
        }
        for (const param of sch.params) {
            if (param.expr) {
                const fn = getFn(param.expr, __globobj__);
                __globobj__[param.name] = fn(values?.[0]);
            }
        }
    }
    
    return schema;
}



/**
 * Read a value. The value can be either 
 * @param obj 
 * @param field 
 * @param type 
 * @returns 
 */
export function readValue(obj: VegaLikeSchemaBase, field: Value, type: "string" | "number" | "boolean" | "any", idx = null) {

	if (field == null) { return null; }
	if (typeof field === "string" || typeof field === "number" || typeof field === "boolean") {
		return type === typeof field || type === "any" ? field : null;
	}
	if (typeof field === "object") {
		if (field.datum != null) { return field.datum; }
		if (field.field) { 
            return idx != null ? obj.data?.values?.[idx]?.[field.field] : (o) => o?.[field.field] 
        }
		if (field.expr) { 
            const fn = getFn(field.expr, obj.__globobj__);
            return idx != null ? fn(obj.data.values?.[idx]) : fn;
        }
	}

	return null;
}


interface VegaLikeOpObj {
    op: string;
    values: any[];
}

/**
 * eval pivot and aggregation data
 * @param op 
 * @returns 
 */
function evalOp(op: VegaLikeOpObj) {

    if (op.op === "sum") {
        return op.values.reduce((sum, c) => sum + c, 0);
    } else if (op.op === "mean" || op.op === "average") {
        return op.values.length > 0 ? op.values.reduce((sum, c) => sum + c, 0) / op.values.length : NaN;
    } else if (op.op === "count") {
        return op.values.length;
    } else if (op.op === "missing") {
        return op.values.filter(v => v == null).length;
    } else if (op.op === "valid") {
        return op.values.filter(v => v != null && Number.isNaN(v) === false).length;
    } else if (op.op === "distinct") {
        return Object.keys(op.values.reduce((set, c) => ({ ...set, [c]: 1 }), {})).length;
    } else if (op.op === "min") {
        return op.values.reduce((min, c) => Math.min(c, min ?? c));
    } else if (op.op === "max") {
        return op.values.reduce((max, c) => Math.max(c, max ?? c));
    } else if (op.op === "values") {
        return op.values;
    } else if (op.op === "median") {
        const sorted = op.values.slice().sort((a, b) => a - b);
        const middle = Math.floor(sorted.length / 2);
        return (sorted.length % 2 === 0 && sorted.length > 1) ? (sorted[middle - 1] + sorted[middle]) / 2 : sorted[middle];
    }
    throw new Error("Unsupported operator " + op.op);
}



const getFn = (code: string, __globobj__): ((datum: any) => any) => {
    const ast = parseExpression(code);
    const fn = codegenExpression({
        fieldvar: "datum",
        constants: vegaConstants,
        functions: vegaFunctions,
        forbidden: ["_", "window", "document"],
        allowed: ["datum"],
        globalvar: "__globobj__",
    })(ast).code;

    const ev = `(datum) => (${fn})`;
    return eval(ev);
}

/**
 * Take a values array and a transformations array and return a new values array that has been transformed
 * according to the list of transformations. The supported transformations are a subset of the vega-lite
 * supported transformations.
 * 
 * @param values 
 * @param transforms 
 * @returns 
 */
export function vegaLikeTransforms(values: any[], transforms: VegaLikeSchemaBase["transform"]) {

    if (!transforms || Array.isArray(transforms) === false || transforms.length === 0) { return values; }

	values = [...values];

	const __globobj__ = {};
	if (!__globobj__) { console.log(__globobj__); }	// This line is just to avoid error in compilation




	for (const trans of transforms) {
		if (typeof trans.calculate === "string" && typeof trans.as === "string") {
			const as = trans.as;
			const fn = getFn(trans.calculate, __globobj__);

			for (let i = 0; i < values.length; i++) {
				values[i] = { ...values[i], [as]: fn(values[i]) };
			}

		} else if (typeof trans.filter === "string") {

			const fn = getFn(trans.calculate, __globobj__);
			values = values.filter(datum => fn(datum));

		} else if (typeof trans.pivot === "string" && typeof trans.value === "string") {

            const doOpOnObj = (datum: {[key: string]: number | string | VegaLikeOpObj}) => {
                for (const key of Object.keys(datum)) {
                    if (typeof datum[key] === "object" && datum[key].op) {
                        datum[key] = evalOp(datum[key]);
                    }
                }
                return datum;
            }


            if (Array.isArray(trans.groupby) && trans.groupby.length > 0) {
                let sets: {
                    [groupby: string]: {
                        [key: string]: number | string | VegaLikeOpObj;
                    }
                } = {};

                for (const datum of values) {
                    const key = datum[trans.pivot];
                    const groupby = trans.groupby.map(k => datum[k]).join(":");

                    let res = sets[groupby];

                    if (!res) {
                        res = sets[groupby] = trans.groupby.reduce((res, k) => ({ ...res, [k]: datum[k] }), {});
                    }
                    if (!res[key]) { res[key] = { op: trans.op || "sum", values: [] }; }
                    (res[key] as VegaLikeOpObj).values.push(datum[trans.value]);

                }
                values = [];
                for (const setKey of Object.keys(sets)) {
                    values.push(doOpOnObj(sets[setKey]));
                }

            } else {
                // No pivot needed
                let res = {};
                for (const datum of values) {
                    const key = datum[trans.pivot];
                    if (!res[key]) { res[key] = { op: trans.op || "sum", values: [] }; }
                    (res[key] as VegaLikeOpObj).values.push(datum[trans.value]);
                }
                values = [doOpOnObj(res)];
            }
		}

	}

	return values;

}

