

import React from "react";
import * as d3 from "d3";

import { DataRateString, IOmuiRadioConfig, ISX1301Conf } from "./RadioConfigIf"
import { Button, ButtonGroup, Form, ToggleButton } from "react-bootstrap";
import { IUiSchemaElemArgs } from "../../schemaengine/client/SchemaController";
import { registerComponentHandler } from "../../schemaengine/client/SchemaExtensions";

import Select from "react-select";



const rfcfgExtension = (args: IUiSchemaElemArgs) => {
	const { key, value, readOnly } = args;
	const update = (rfcfg: any) => {
		args.update({ value: rfcfg });
	}
	return <RadioConfig
			key={key}
			readOnly={readOnly}
			radioConfig={value.radioConfig}
			resetRadioConfig={value.resetRadioConfig}
			gatewayCapabilities={value.gatewayCapabilities}
			freqRange={value.freqRange}
			region={value.region}
			update={update}
	/>
};

registerComponentHandler("customRfcfg", rfcfgExtension);






type RadioType = "v1" | "v1.5" | "v2" | "v2.1" | "array" | "ism2400ref_3";
interface IGatewayCapabilities {
	radio_type: RadioType;
	freq_update: boolean;
	bands_supported: Array<{
		beg: number;
		end: number;
	}>
}
interface IRadioConfigProps {
	readOnly: boolean;
	radioConfig: IOmuiRadioConfig;
	resetRadioConfig: IOmuiRadioConfig;
	gatewayCapabilities: IGatewayCapabilities;
	freqRange: number[];
	update: (rfCfg: IOmuiRadioConfig) => void,
	region: string;
}

interface IRadioConfigState {
	omcConfig: IOmuiRadioConfig;
	snapKhz: number;
	zoom: number;
	activeIdx: number;
}


interface IData {
	idx: number;		// position in the array
	freq: number;
	sx: number;
	ch: number;
	radio: number;
	antenna: number;
	bw: number;
	enabled: boolean;
	color: string;
	h: number;
	lastClick: number;
	type: "window" | "mchan" | "std" | "fsk";
	minsf: number;
	maxsf: number;
	fskrate: number;

	// internal
	changed?: boolean;
	error?: boolean;
}

const mhz2hz = (mhz: number) => Math.round(mhz * 1000000);
const hz2mhz = (hz: number) => (hz / 1000000);


export class RadioConfig extends React.Component<IRadioConfigProps, IRadioConfigState> {

	public svgRef = React.createRef<SVGSVGElement>();
	public axisRef = React.createRef<SVGGElement>();
	public tooltipRef = React.createRef<HTMLDivElement>();
	public overlayRef = React.createRef<any>();
	public containerDivRef = React.createRef<HTMLDivElement>();

	public usedWidth = 0;
	public width = 0;
	public height = 120;

	public data: IData[] = [];

	// parameters (static after init)
	public radioType: RadioType = "v1";
	public numChan = 8;
	public numSX130x = 1;
	public minFreq = 863;
	public maxFreq = 870;




	constructor(props: IRadioConfigProps) {
		super(props);

		this.state = {
			omcConfig: {} as any,
			snapKhz: 100,
			zoom: 1,
			activeIdx: -1,
		};
	}


	initData = (omcConfig: IOmuiRadioConfig, gtwCap: IGatewayCapabilities) => {

		// Initialise the D3 polygons for the SVG

		const colorMchan = "rgba(0, 190, 0, 0.4)"; //"rgba(0, 190, 0, 0.8)";
		const colorStd = "rgba(20, 120, 190, 0.4)"; //"rgba(0, 190, 0, 0.8)";
		const colorFsk = "rgba(150, 80, 190, 0.4)"; //"rgba(0, 190, 0, 0.8)";
		const colorWindow = "rgba(190, 215, 215, 0.8)";

		this.data = [];
		this.radioType = gtwCap?.radio_type || "v1";
		if (gtwCap?.bands_supported && gtwCap.bands_supported[0]) {
			this.minFreq = gtwCap.bands_supported[0].beg;
			this.maxFreq = gtwCap.bands_supported[0].end;
		}

		const regionsWithoutFsk = ["US915", "AU915", "ISM2400"];

		// Get the valid range from the freq range input, which is defined by the region.
		if (this.props.freqRange) {
			this.minFreq = hz2mhz(this.props.freqRange[0]);
			this.maxFreq = hz2mhz(this.props.freqRange[1]);
		}




		if (!omcConfig) { return; }

		function ridx(idx: number) { return idx >= 0 ? Math.min(idx, 1) : Math.min(-idx - 1, 1); }


		// First render the radio windows. Note that for the ism2400ref_3 radio that uses 3 device
		// chips instead of the concentrator, there is no radio window.
		let antenna = 0;
		const numChanPerSx = this.radioType === "ism2400ref_3" ? 3 : 8;
		const minsf        = this.radioType === "ism2400ref_3" ? 5 : 7;


		// Use calcfreq to calculate the actual frequency
		const calcfreq = (radio: number, offset: number, sxobj: ISX1301Conf) => {
			let freq = hz2mhz(offset + (radio >= 0 ? sxobj.radio[radio].freq : 0));
			if (freq < this.minFreq) {
				if (radio < 0) { freq = this.minFreq;
				} else { this.minFreq = freq; }
			}
			return freq;
		}



		if (this.radioType !== "ism2400ref_3") {
			const bw = this.radioType.startsWith("array") ? 13 : this.radioType.startsWith("v2") ? 3 : 1;
			const radioPerSx = this.radioType.startsWith("v1") ? 2 : 1;

			this.numSX130x = this.radioType.startsWith("array") ? 1 : (omcConfig.SX1301s_conf || []).length;

			for (let sx = 0; sx < this.numSX130x; sx++) {

				// for v2 radio (with one freq per sx) we have to check which radio was used
				// so we can select the correct radio object. This is because in the old NST
				// only the selected radio would be initialised, whereas in the default radio
				// configurations both radios are initialised with the same values
				const selectedRadio = ridx(omcConfig.SX1301s_conf[sx].chan_multiSF[0].radio);
				const sxobj = omcConfig.SX1301s_conf[sx];

				antenna = 0;
				if (this.radioType.startsWith("v2")) {
					for (const msf of sxobj.chan_multiSF) { antenna = Math.max(antenna, msf.radio); }
					antenna = Math.max(antenna, sxobj.chan_FSK.radio);
					antenna = Math.max(antenna, sxobj.chan_Lora_std.radio);
				}

				for (let radio = 0; radio < radioPerSx; radio++) {
					const radioObj = sxobj.radio[radioPerSx === 2 ? radio : selectedRadio];
					this.data.push({
						idx: this.data.length,
						freq: hz2mhz(radioObj.freq), bw, radio,
						enabled: radioObj.enable, color: colorWindow, h: 1.5,
						sx, ch: null, antenna, lastClick: 0, type: "window",
						minsf: null, maxsf: null, fskrate: null,
					});
				}
			}


			for (let sx = 0; sx < (omcConfig.SX1301s_conf || []).length; sx++) {

				// Add the Standard channel
				const sxobj = omcConfig.SX1301s_conf[sx];
				const std = sxobj.chan_Lora_std;
				const fsk = sxobj.chan_FSK;


				antenna = 0;
				if (this.radioType.startsWith("v2")) {
					for (const msf of sxobj.chan_multiSF) { antenna = Math.max(antenna, msf.radio); }
					antenna = Math.max(antenna, sxobj.chan_FSK.radio);
					antenna = Math.max(antenna, sxobj.chan_Lora_std.radio);
				}

				this.data.push({
					idx: this.data.length,
					freq: calcfreq(std.radio, std.offset, sxobj),
					bw: hz2mhz(std.bandwidth), enabled: std.radio >= 0, radio: ridx(std.radio),
					color: colorStd, h: 1, sx, ch: 0, antenna, lastClick: 0, type: "std",
					minsf: Math.max(minsf, std.spread_factor), maxsf: null, fskrate: null,
				});


				if (!regionsWithoutFsk.includes((this.props.region || "").toUpperCase())) {
					// For FSK we for the moment simply force the settings.
					// FSK50000 @ BW125
					this.data.push({
						idx: this.data.length,
						freq: calcfreq(fsk.radio, fsk.offset, sxobj),
						bw: 0.125, enabled: fsk.radio >= 0, radio: ridx(fsk.radio),
						color: colorFsk, h: 1, sx, ch: 0, antenna, lastClick: 0, type: "fsk",
						minsf: null, maxsf: null, fskrate: 50000,
					});
				}
			}
		}

		// Now render the channels
		for (let sx = 0; sx < (omcConfig.SX1301s_conf || []).length; sx++) {
			const sxobj = omcConfig.SX1301s_conf[sx];

			antenna = 0;
			if (this.radioType.startsWith("v2")) {
				for (const msf of sxobj.chan_multiSF) { antenna = Math.max(antenna, msf.radio); }
				antenna = Math.max(antenna, sxobj.chan_FSK.radio);
				antenna = Math.max(antenna, sxobj.chan_Lora_std.radio);
			}

			for (let ch = 0; ch < numChanPerSx; ch++) {
				const msf = sxobj.chan_multiSF[ch];

				// Note, we don't use the enable per channel, but check if the radio is < 0 instead
				this.data.push({
					idx: this.data.length,
					freq: calcfreq(msf.radio, msf.offset, sxobj),
					bw: hz2mhz(msf.bandwidth), enabled: msf.radio >= 0, radio: ridx(msf.radio),
					color: colorMchan, h: 1, sx, ch, antenna, lastClick: 0, type: "mchan",
					minsf: parseInt(msf.minsf && msf.minsf.substring(2) || "7"),
					maxsf: parseInt(msf.maxsf && msf.maxsf.substring(2) || "12"),
					fskrate: null,
				});
			}
		}

		this.sanitizeData();
	}


	updateConfig() {
		// Do a deep copy of the radio config before we start modifying it
		const newConfig: IOmuiRadioConfig = JSON.parse(JSON.stringify(this.props.radioConfig));
		this.sanitizeData();

		// const numChan = this.radioType === "ism2400ref_3" ? 3 : 8;
		for (const elem of this.data) {

			const enabled = elem.enabled && !elem.error;

			if (elem.type === "window") {

				const radio = newConfig.SX1301s_conf[elem.sx].radio[elem.radio];
				radio.enable = elem.enabled;
				radio.freq   = mhz2hz(elem.freq);

				if (this.radioType.startsWith("v2") || this.radioType.startsWith("array")) {
					newConfig.SX1301s_conf[elem.sx].radio[1] = newConfig.SX1301s_conf[elem.sx].radio[0];
				}

				// For array type we only keep the window config in the first SX130x, so here we now copy the configuration
				// from elem zero to all the other fields SX130x.
				if (this.radioType.startsWith("array")) {
					for (let sxIdx = 1; sxIdx < this.numSX130x; sxIdx++) {
						newConfig.SX1301s_conf[sxIdx].radio = newConfig.SX1301s_conf[0].radio.map(r => ({ ...r }));
					}
				}

			} else if (elem.type === "mchan") {

				const mchan = newConfig.SX1301s_conf[elem.sx].chan_multiSF[elem.ch];
				mchan.bandwidth = mhz2hz(elem.bw);
				mchan.enable    = enabled;
				mchan.radio     = enabled ? elem.radio : -elem.radio - 1;
				mchan.offset    = mhz2hz(elem.freq) - (enabled ? newConfig.SX1301s_conf[elem.sx].radio[elem.radio].freq : 0);
				mchan.minsf     = ("SF" + elem.minsf) as DataRateString;
				mchan.maxsf     = ("SF" + elem.maxsf) as DataRateString;

			} else if (elem.type === "std") {

				const std = newConfig.SX1301s_conf[elem.sx].chan_Lora_std;
				std.bandwidth = mhz2hz(elem.bw);
				std.enable    = enabled;
				std.radio     = enabled ? elem.radio : -elem.radio - 1;
				std.offset    = mhz2hz(elem.freq) - (enabled ? newConfig.SX1301s_conf[elem.sx].radio[elem.radio].freq : 0);
				std.spread_factor = elem.minsf;

			} else if (elem.type === "fsk") {

				const fsk     = newConfig.SX1301s_conf[elem.sx].chan_FSK;
				fsk.bandwidth = mhz2hz(elem.bw);
				fsk.enable    = enabled;
				fsk.radio     = enabled ? elem.radio : -elem.radio - 1;
				fsk.offset    = mhz2hz(elem.freq) - (enabled ? newConfig.SX1301s_conf[elem.sx].radio[elem.radio].freq : 0);
				fsk.datarate  = elem.fskrate;
			}
		}

		this.props.update(newConfig);
	}


	sanitizeData = () => {

		const windows = this.data.filter(d => d.type === "window");
		const arr = this.radioType.startsWith("array");

		for (const elem of this.data) {

			if (elem.type === "mchan" || elem.type === "fsk" || elem.type === "std") {
				// find best window
				let mindist = null;
				let bestwin: IData = null;
				for (const win of windows) {
					// Match the SX, except in the case of the ARRAY, where we always use the SX0 as a common windows
					if ((arr || elem.sx === win.sx) && win.enabled) {
						let dist = Math.abs(mhz2hz(elem.freq) - mhz2hz(win.freq));
						if (mindist == null || dist < mindist) {
							mindist = dist;
							bestwin = win;
						}
					}
				}

				// Compensate the bandwidth of the radio window. The width depends on the width of the channel itself
				// TODO: find same compensation for v2 gateways
				let bestwinBw = mhz2hz(bestwin?.bw);
				const elemBw = mhz2hz(elem?.bw);
				if (bestwinBw === 1000000) {
					switch (elemBw) {
						case 125000: bestwinBw = 925000; break;
						case 250000: bestwinBw = 1000000; break;
						case 500000: bestwinBw = 1100000; break;
					}
				}

				elem.error = !!(elem.enabled && bestwin && mindist > bestwinBw / 2 - elemBw / 2);

				// For the v1 style radio we need to select the window that best fit the channel
				if (this.radioType.startsWith("v1")) {
					elem.radio = bestwin.radio || 0;
				}
			}
		}
	}


	drawD3 = (transition: boolean) => {

		if (this.width === 0) { return; }

		this.usedWidth = this.width;
		const me = this;
		const minFreq = this.minFreq;
		const maxFreq = this.maxFreq;
		const margin  = 30;
		const baseline = 80;
		const width    = (this.width - 3) * this.state.zoom - margin;
		const readOnly = this.props.readOnly || this.props.gatewayCapabilities?.freq_update == false;

		const freqToPixelRatio = width / (maxFreq - minFreq);
		const freqToPixel = (freq: number) => (freq - minFreq) * freqToPixelRatio;
		const PixelToFreq = (pix: number) => pix / freqToPixelRatio + minFreq;

		// const xScale = d3.scaleLinear().domain([-10, 50]).range([0, this.width]);
		// const yScale = d3.scaleLinear().domain([-3, 8]).range([0, height]);


		function trapezoid(d: IData, bl: number) {
			const { bw, enabled, h, antenna } = d;
			bl -= 40 * antenna;

			return [
				{ x: -(bw * freqToPixelRatio / 2),     y: bl },
				{ x: -(bw * freqToPixelRatio / 2) + 3, y: bl - h * (enabled ? 20 : 10) },
				{ x:  (bw * freqToPixelRatio / 2) - 3, y: bl - h * (enabled ? 20 : 10) },
				{ x:  (bw * freqToPixelRatio / 2),     y: bl },
			];
		}


		//d3.select("#tooltipxx").remove();
		d3.select('body').append('div').attr('id', 'tooltipxx')
				.attr("class", "tooltip")
				.style("position", "absolute")
				.style("opacity", 0)
				.style("word-wrap", "break-word")
				.style("z-index", "10")
				.style("background-color", "white")
				.style("border", "solid")
				.style("border-width", "2px")
				.style("border-radius", "5px")
				.style("padding", "5px")

		const tooltip = (x: number, y: number, text: string) => {
			if (!text) {
				d3.select('#tooltipxx').style('opacity', 0)
			 } else {
				const yy = window.innerHeight - y + 20;
				d3.select('#tooltipxx').style('left', x + 'px').style('bottom', yy + "px").
						style("stroke", "black").style('opacity', 1).html(text.trim());
			 }
		};

		const toolText = (d: IData) => {
			const sx   = this.radioType === "ism2400ref_3" ? "" : " sx:" + d.sx;
			const freq = " freq: " + d.freq + "MHz";
			const ant  = this.radioType === "ism2400ref_3" ? "" :
							this.radioType.startsWith("v1") ? " radio:" + d.radio : " ant:" + d.antenna;


			if (d.type === "fsk") {
				return sx + ant + " FSK:" + d.fskrate + freq;
			} else if (d.type === "std") {
				return sx + ant + " STD-Chan: SF" + d.minsf + freq;	
			} else if (d.type === "mchan") {
				return sx + ant + " ch:" + d.ch + freq;	
			} else if (d.type === "window") {
				return sx + ant + " window " + freq;	
			} else {
				return "";
			}
		}


		const svgElem = this.svgRef.current;
		const blocks = d3.select<SVGSVGElement, IData>(svgElem).selectAll<SVGPolygonElement, IData>("polygon").data<IData>(this.data);
		const active = this.state.activeIdx >= 0 ? this.data[this.state.activeIdx] : null;

		const colorselected = "rgba(150, 80, 190, 1)"; //"rgba(0, 190, 0, 0.8)";
		const colorserror   = "rgba(250, 0, 0, 1)"; //"rgba(0, 190, 0, 0.8)";


		if (transition) {
			blocks.enter().append("polygon").merge(blocks).transition().duration(400)
				.attr("points", d => trapezoid(d, baseline).map(p => [p.x, p.y].join(",")).join(" "))
				.attr("transform", d => { return "translate(" + (freqToPixel(d.freq) + margin/2) + "," + 0 + ")" })
				.style("fill", d => d.error ? colorserror : d === active ? colorselected : d.color);
		} else {
			blocks.enter().append("polygon").merge(blocks)
				.attr("points", d => trapezoid(d, baseline).map(p => [p.x, p.y].join(",")).join(" "))
				.attr("transform", d => { return "translate(" + (freqToPixel(d.freq) + margin/2) + "," + 0 + ")" })
				.style("fill", d => d.error ? colorserror : d === active ? colorselected : d.color);
		}

		blocks.on('mouseover', function(ev, d) {
			tooltip(ev.pageX, ev.pageY, toolText(d));
		})
		.on('mouseout', function(ev, d) {
			tooltip(ev.pageX, ev.pageY, "");
		})
		.on('mousemove', function(ev, d) {
			tooltip(ev.pageX, ev.pageY, toolText(d));
		});


		const scale = d3.scaleLinear().domain([minFreq, maxFreq]).range([0, width]);
		const axis = d3.axisBottom(scale);

		// Draw the axis
		d3.select(this.axisRef.current).call(axis).attr("transform", "translate(" + margin/2 + ", " + baseline + ")");
		blocks.exit().remove();

		let offset = 0;
		const snap = this.state.snapKhz * 1000;
		const sanitize = () => this.sanitizeData();


		// Setup the drag handler
		const dragHandler = d3.drag<SVGPolygonElement, IData>()
    		.on("start", function (ev, d) {

				me.setState({ activeIdx: d.idx });

				if (readOnly) { return; }

				offset = d3.pointer(ev, this)[0];
				d.changed = false;
			})
			.on("drag", function (ev, d) {

				if (readOnly) { return; }

				const freq = PixelToFreq(d3.pointer(ev, svgElem)[0] - offset - margin/2);
				const freqRnd = Math.round(freq * 1000000 / snap) * snap / 1000000;
				const newFreq = Math.min(Math.max(freqRnd, minFreq), maxFreq);

				if (d.freq !== newFreq) { d.changed = true; }
				d.freq = newFreq;

				sanitize();

				blocks.enter().append("polygon").merge(blocks)
					.attr("points", d => trapezoid(d, baseline).map(p => [p.x, p.y].join(",")).join(" "))
					.attr("transform", d => { return "translate(" + (freqToPixel(d.freq) + margin/2) + "," + 0 + ")" })
					.style("fill", d => d.error ? colorserror : d === active ? colorselected : d.color);

			})
			.on("end", (ev, d) => {

				if (readOnly) { return; }

				const now = Date.now();

				if (now - d.lastClick < 500) {
					d.enabled = !d.enabled;
					d.changed = true;
					this.drawD3(true);
				}
				d.lastClick = now;
				if (d.changed) {
					this.updateConfig();
				}
			});

		dragHandler(blocks);
	}


	updateWidth() {
		if (this.containerDivRef.current) {
			// Get the inner width of the container div. The math expression is copied from jquery innerWidth()
			const element = this.containerDivRef.current;
			const width = Math.max(element.scrollWidth, element.offsetWidth, element.clientWidth);
			if (width > 0) { this.width = width; }
		}
	}


	componentDidMount(): void {

		this.updateWidth();
		if (this.props.radioConfig) {
			this.initData(this.props.radioConfig, this.props.gatewayCapabilities);
			this.setState({ omcConfig: this.props.radioConfig });
			this.drawD3(true)
		} else {
			this.drawD3(false);
		}
	}

	componentDidUpdate(prevProps: Readonly<IRadioConfigProps>, prevState: Readonly<IRadioConfigState>, snapshot?: any): void {

		this.updateWidth();
		if (this.props.radioConfig !== this.state.omcConfig || this.usedWidth != this.width) {
			this.initData(this.props.radioConfig, this.props.gatewayCapabilities);
			this.setState({ omcConfig: this.props.radioConfig });
			// only call this if there are really any changes
			this.drawD3(true);
		} else {
			this.drawD3(false);
		}
	}


	render () {

		const readOnly = this.props.readOnly|| this.props.gatewayCapabilities?.freq_update == false;
		const snapInKhz = [100, 10, 5];
		const zoomFactors = [1, 2, 4, 8]

		const snap: JSX.Element[] = snapInKhz.map<JSX.Element>(v =>
			(<ToggleButton type="radio" id={"id" + v}
				key={"key" + v} disabled={readOnly} value={v} checked={this.state.snapKhz === v} variant="outline-secondary"
				onClick={() => {!readOnly && this.setState({ snapKhz: v }); }}
			>
				{v}kHz
			</ToggleButton>
		));

		const zoom: JSX.Element[] = zoomFactors.map<JSX.Element>(v =>
			(<ToggleButton type="radio" id={"id" + v}
				key={"key" + v} value={v} checked={this.state.zoom === v} variant="outline-secondary"
				onClick={() => this.setState({ zoom: v }) }
			>
				{v}x
			</ToggleButton>
		));


		interface ISelectValueNumber {
			label: string;
			value: number;
		}

		const ch = this.state.activeIdx >= 0 ? this.data[this.state.activeIdx] : null;

		const fskrateValues = [50000];
		const fskrateSelectOptions: ISelectValueNumber[] = fskrateValues.map(v => ({ value: v, label: v + " bps" }));

		const bwValues = ch?.type === "std" ? [0.125, 0.250, 0.500] :
						 ch?.type === "fsk" ? [/*0.0078, 0.0156, 0.0312, 0.0625,*/ 0.125 /*, 0.250, 0.500*/] : [0.125];
		const bwSelectOptions: ISelectValueNumber[] = bwValues.map(v => ({ value: v, label: "BW" + (v * 1000) }));

		const minsf    = this.props.region === "ISM2400" ? 5 : 7;
		const sfValues = this.props.region === "ISM2400" ? [5, 6, 7, 8, 9, 10, 11, 12] : [7, 8, 9, 10, 11, 12]
		const sfSelectOptions: ISelectValueNumber[] = sfValues.map(v => ({ value: v, label: "SF" + v }));

		const antValues = [0, 1];
		const antSelectOptions: ISelectValueNumber[] = antValues.map(v => ({ value: v, label: "Ant" + v }));

		const fromSF  = ch && ch.minsf ? sfSelectOptions.find(v => v.value === ch.minsf) : null;
		const toSF    = ch && ch.maxsf ? sfSelectOptions.find(v => v.value === ch.maxsf) : null;
		const bw      = ch && ch.bw ? bwSelectOptions.find(v => v.value === ch.bw) : null;
		const fskrate = ch && ch.fskrate ? fskrateSelectOptions.find(v => v.value === ch.fskrate) : null;
		const ant     = ch && ch.antenna != null ? antSelectOptions.find(v => v.value === ch.antenna) : null;
		const freq    = ch && ch.freq;

		let fromSfSelectOptions = sfSelectOptions;
		let toSfSelectOptions   = sfSelectOptions;

		if (ch?.minsf && ch?.maxsf && ch.minsf <= ch.maxsf) {
			fromSfSelectOptions = fromSfSelectOptions.slice(0, ch.maxsf - minsf + 1);
			toSfSelectOptions   = toSfSelectOptions.slice(ch.minsf - minsf);
		}

		// Define the handlers
		const setMinSF = (ev: ISelectValueNumber) => {   ch.minsf = ev.value;   this.updateConfig(); }
		const setMaxSF = (ev: ISelectValueNumber) => {   ch.maxsf = ev.value;	this.updateConfig(); }
		const setBW = (ev: ISelectValueNumber) => {      ch.bw = ev.value;	    this.updateConfig(); }
		const setFskrate = (ev: ISelectValueNumber) => { ch.fskrate = ev.value; this.updateConfig(); }
		const setFreq = (val: any) => {
			ch.freq = parseFloat(val.target.value || 0); this.updateConfig();
		}
		const setAnt = (ev: ISelectValueNumber) => {
			if (this.radioType.startsWith("v2")) {
				// for v2 type config we need to set all radio settings for the sx1301
				for (const c of this.data) {
					if (c.sx == ch.sx) {
						c.radio = ev.value
					}
				}
			}
			this.updateConfig();
		}
		const toggle = () => {
			let ena: boolean = null;
			for (const c of this.data) {
				if (c.type === "mchan") {
					if (ena == null) { ena = !c.enabled; }
					c.enabled = ena;
				}
			}
			this.updateConfig();
		}



		const sfReadOnly = readOnly || (this.props.region !== "ISM2400" && ch?.type === "mchan");
		const antReadOnly = readOnly || !this.radioType.startsWith("v2");

		return (<div ref={this.containerDivRef}>
			<div className="mb-2" style={{overflowX: "scroll"}}>
				<svg width={this.width * this.state.zoom} height={this.height} ref={this.svgRef}>
					<g ref={this.axisRef} />
				</svg>
			</div>


			<ButtonGroup className="mr-2 mb-2">
				{snap}
			</ButtonGroup>
			<ButtonGroup className="mr-2 mb-2">
				{zoom}
			</ButtonGroup>

			<ButtonGroup className="mr-2 mb-2">
				<Button variant="outline-secondary" disabled={readOnly} onClick={toggle} >Toggle</Button>
				<Button variant="outline-secondary" disabled={readOnly}
						onClick={() => this.props.update(this.props.resetRadioConfig)} >Reset</Button>
			</ButtonGroup>

			{(fromSF || toSF) &&
				<ButtonGroup className="mr-2 mb-2">
					<Select isDisabled={sfReadOnly} options={fromSfSelectOptions} value={fromSF} onChange={setMinSF}/>
					{toSF && <Select isDisabled={sfReadOnly} options={toSfSelectOptions} value={toSF} onChange={setMaxSF}/>}
				</ButtonGroup>
			}

			{bw && <ButtonGroup className="mr-2 mb-2">
				<Select isDisabled={sfReadOnly} options={bwSelectOptions} value={bw} onChange={setBW}/>
			</ButtonGroup>}

			{fskrate && <ButtonGroup className="mr-2 mb-2">
				<Select isDisabled={readOnly} options={fskrateSelectOptions} value={fskrate} onChange={setFskrate}/>
			</ButtonGroup>}

			{ant && <ButtonGroup className="mr-2 mb-2">
				<Select isDisabled={antReadOnly} options={antSelectOptions} value={ant} onChange={setAnt}/>
			</ButtonGroup>}


			{freq != null && <ButtonGroup className="mr-2 mb-2">
				<Form.Control value={freq} onChange={setFreq} type="number"	readOnly={readOnly} />
			</ButtonGroup>}





		</div>);

	}

}
