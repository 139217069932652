/**
 * import useful library functions
 */

import * as jsonc from "jsonc-parser";
import { registerExtensionLibFunction } from "./SchemaExtensions";



function modifyJson(json: string, modifyKeys: {
    [keypath: string]: object | string | number | boolean | null | undefined;
}) {

    const options: jsonc.ModificationOptions = { formattingOptions: { insertSpaces: true, tabSize: 4 } };
    for (const key of Object.keys(modifyKeys)) {
        const path = key.split("/").filter(pe => pe !== "").map(pe => String(parseInt(pe)) === pe ? parseInt(pe) : pe);

        const edits = jsonc.modify(json, path, modifyKeys[key], options);
        json = jsonc.applyEdits(json, edits);
    }

    return json;
}


registerExtensionLibFunction("modifyJson", modifyJson);

