import React, { useEffect, useState } from "react";

import { AttributionControl, MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch';
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css"

import { IUiSchemaElemArgs } from "./SchemaController";
import { registerComponentHandler } from "./SchemaExtensions";



// Imports for map extensions
import L, { LatLng } from 'leaflet'
import ReactDOMServer from "react-dom/server";


const DefaultMarkerIcon = L.divIcon({
    iconSize: [16, 24],
    iconAnchor: [8, 24],
    popupAnchor: [1, -30],
    className: "default_icon",
    html: ReactDOMServer.renderToStaticMarkup(<i className="fa-solid fa-location-dot fa-2x" style={{color: "blue"}} />)
});

const SearchMarkerIcon = L.divIcon({
    iconSize: [16, 24],
    iconAnchor: [8, 24],
    popupAnchor: [1, -30],
    className: "default_icon",
    html: ReactDOMServer.renderToStaticMarkup(<i className="fa-regular fa-magnifying-glass-location fa-2x" style={{color: "red"}} />)
});




const MapViewExtension = (props: { args: IUiSchemaElemArgs }) => {

	const { args } = props;
	const { key, value, readOnly } = args;
	let { lat, lng } = value as { lat: number, lng: number };

	const def = args.getSettings("default-map-center");
	const zoom = def?.zoom || 13;
	const defcenter = { lat: def?.lat || 0, lng: def?.lng || 0 };

	const positionSet = lat != null && lng != null && !isNaN(lat) && !isNaN(lng);

	if (!positionSet) {
		lat = defcenter.lat;
		lng = defcenter.lng;
	}
	const center = { lat, lng };

	const markerHandlers = {
		dragend: (e: any) => {
			let { lat, lng } = e.target._latlng;
			lng = lng % 360;
			lng = lng > 180 ? lng - 360 : lng < -180 ? lng + 360 : lng; 
			const k = 1000000;
			args.update({value: { lat: Math.round(lat * k) / k, lng: Math.round(lng * k) / k }});
		},
	};

	function ChangeView(props: { center: L.LatLngExpression, zoom: number }) {
		const { center, zoom } = props;
		// This is a bit of a hack. The problem is that the schema modal may draw the map before the
		// the data with the location has been read. In this case updating the center after it has been
		// rendered first time will not update the center, as the properties are immutable. So we check
		// if the current center is 0,0 and there is a new center location that is not 0,0, we update
		// the map center.
		const map = useMap();
		const cur = map.getCenter();
		if (cur.lat === defcenter.lat && cur.lng === defcenter.lng && ((center as LatLng).lat || (center as LatLng).lng)) {
			map.setView(center, zoom);
		}
		return null;
	}

	const [markerRef, setMarkerRef] = useState<L.Marker>(null);



	const SearchControl = (props) => {
		const map = useMap();
		
		useEffect(() => {
			const searchControl = GeoSearchControl({
				provider: new OpenStreetMapProvider(),
				marker: { icon: SearchMarkerIcon },
				...props,
		  	});
	  
		  	map.addControl(searchControl);
		  	return () => map.removeControl(searchControl) as any;
		}, [props]);
	  
		return null;
	};


	useEffect(() => {
		markerRef?.openPopup();
	}, [markerRef]);

	return (
		<MapContainer attributionControl={false} key={key} center={center} zoom={zoom} className="mb-2" >
			<ChangeView center={center} zoom={zoom} />
			<AttributionControl prefix='<a href="https://leafletjs.com/">Leaflet</a>' />
			<TileLayer
				attribution="&amp;copy <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>

			{(!readOnly || positionSet) &&
				<Marker ref={(marker) => setMarkerRef(marker)} position={center} icon={DefaultMarkerIcon} draggable={!readOnly} eventHandlers={markerHandlers}>
					{!positionSet && <Popup>
						Drag marker to set position
					</Popup>}
				</Marker>
			}

			<SearchControl />

		</MapContainer>
	);
};



registerComponentHandler("mapView", (args: IUiSchemaElemArgs) => <MapViewExtension key={args.fullkey} args={args}/>);
