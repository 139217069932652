export const english = {
    BUTTON_LANG_EN: 'English',
    BUTTON_LANG_ZH: '中文',
    BUTTON_LANG_FR: 'Français',
    BUTTON_LANG_AR: "Arabic",
    BUTTON_LANG_DD: 'See text names',

    EDIT_GLOBAL_PUSH_CONFIG: 'Edit Default Push Config',

    // Navigation
    NAV_USER_SETTINGS: 'User Settings',
    NAV_RETURN_TO: 'Return to {originalUser}',
    NAV_SOFTWARE_INFO: "License information",

    RETURN_TO_OLD_USER: 'Return to Old user',
    NAV_DEVICES_ITEM: 'Devices',
    NAV_MANAGE_GROUPS: 'Groups',
    NAV_MAP: 'Map',

    NAV_GATEWAYS_ITEM: 'Gateways',
    NAV_GATEWAYS_LIST_GATEWAYS: 'Manage Gateways',
    NAV_GATEWAY_TRACE: "View Network Trace",

    NAV_APPLICATIONS_ITEM: 'Applications',

    NAV_LOGINS_ITEM: 'Login',
    NAV_USERS_ITEM: 'Users',
    ADD_ACCOUNT: "Add Account",
    NAV_LOGINS_LIST_USERS: 'Manage User Logins',
    NAV_USERS_LIST_USERS: 'Manage Users',
    NAV_ORGANIZATION_LIST_USERS: 'Manage Organisation',

    NAV_CUSTOMERS_ITEM: 'Customers',
    NAV_CUSTOMERS_LIST_CUSTOMERS: 'Manage Customers',
    NAV_DASHBOARD: 'Dashboard',
    NAV_SYSTEM: 'System',

    NAV_HELP: 'Help',
    NAV_FUOTA_CAMPAIGNS_ITEM: 'FUOTA Campaigns',

    NAV_SWITCH_TO_NST: "Switch to NST",


    NAV_JOIN_SERVERS: "Join Servers",
    NAV_GLOBAL_JOIN_SERVERS: "Global Join Servers",

    NAV_NST_GATEWAYS: "Gateways",
    NAV_NST_DEVICES: "Devices",
    NAV_NST_SERVERS: "Servers",
    NAV_NST_ALARMS: "Alarms",
    NAV_NST_GATEWAY_ALARMS: "Gateway Alarms",
    NAV_NST_SERVER_ALARMS: "Server Alarms",

    NAV_NST_TENANTS: "Tenants",
    NAV_NST_AUDIT_TRAIL: "Audit Trail",
    NAV_NST_AUDIT_TRAIL_GATEWAYS: "Gateway Audit Trail",

    NAV_NST_JOBS: "Jobs",
    NAV_NST_ANALYTICS: "Analytics",
    NAV_NST_GATEWAY_REPORT: "Gateway Report",

    NAV_NST_GENERAL_SETTINGS: "General settings",
    NAV_NST_SERVER_VERSIONS: "Server version",
    NAV_NST_SWITCH_TO_APP: "Switch to APP",

    MAP_DEVICES: "Devices",
    MAP_GATEWAYS: "Gateways",


    // Welcome
    WELCOME_LEARN_MORE: 'Learn More',

    // My Apps
    MY_APPS_ADD_APP: 'Add Application',
    MY_APPS_APP: 'Application',
    MY_APPS_ACTION_EDIT: 'Edit App',
    MY_APPS_ACTION_DELETE: 'Delete App',
    MY_APPS_ACTION_BULK_DELETE: 'Delete Apps',
    MY_APPS_STOP_DEFAULT_PUSH: "Stop Default Push",
    MY_APPS_START_DEFAULT_PUSH: "Start Default Push",


    APP_ID: 'App ID',
    UUID: 'UUID',

    // My devices
    MY_DEVICES_TITLE: 'Devices',
    MY_DEVICE_SUSPEND: 'Suspend Device',
    MY_DEVICE_UNSUSPEND: 'Unsuspend Device',
    MY_DEVICES_DETAIL: 'Device Detail',
    MY_DEVICES_ACTION_SEND: 'Send to device',
    MY_DEVICES_ACTION_DATA: 'View Data',
    MY_DEVICES_ACTION_EDIT_DEVICES: 'Edit Devices',
    MY_DEVICES_ACTION_DELETE_DEVICES: 'Delete Devices',
    BULK_DEVICE_DELETE_TOAST_SUCCESS_MESSAGE: "Devices successfully deleted",
    MY_DEVICES_ACTION_DELETE: 'Delete Device',
    MY_DEVICES_DATA: "Device Data",
    FOR_DEVICE: "for Device ",

    MY_DEVICES_APPLICATIONS: "Apps",
    MY_DEVICES_GROUPS: "Groups",
    GROUP_ASSIGNMENT: "Group Assignment",
    APP_ASSIGNMENT: "App Assignment",
    EDIT_GROUP: "Group Info",
    DELETE_GROUP: "Delete Group",
    DELETE_RULE: "Delete Rule",
    SEND_MULTICAST: "Send Multicast",
    GROUP_ID: "Group ID",
    GROUP_TITLE: "Title",
    ADD_GROUP: "Group",
    ADD_GROUP_TEXT: "Add Group",

    // Network Activity

    // Group Activity
    GROUP_ACTIVITY_TITLE: "Group Activity",

    // Applications
    MY_APPLICATIONS_TITLE: "Applications",
    APPLICATIONS_ADD: "Add Application",

    // Find Devices
    FIND_DEVICES_TITLE: "Find Devices",

    // Find Users
    FIND_USERS_TITLE: "Find Accounts",
    FIND_USERS_TABLE_ACCOUNT_TYPE: "Account type",
    FIND_USERS_TABLE_ADMINISTRATOR: "Administrator",
    FIND_USERS_TABLE_REGISTRATION_RIGHTS: "Registration Rights",
    FIND_USERS_TABLE_PARENT: "Parent",


    // Batch Registration
    ERROR: "Error",
    BATCH_REGISTER_DEVICES_ERROR1: 'There is an empty field at column {column_num}',
    BATCH_REGISTER_DEVICES_ERROR2: 'Missing some of the mandatory fields DevEUI, device_profile_uuid and service_profile_uuid in the header',
    BATCH_REGISTER_DEVICES_SERVER_ERROR: 'Registering the device on row {index} failed because of - {message_error}',
    BATCH_UPDATE_DEVICES_SERVER_ERROR: 'Updating the device on row {index} failed because of - {message_error}',
    BATCH_ERROR_REGISTERING: 'Error registering devices',
    BATCH_ERROR_UPDATE: 'Error updating devices',
    BATCH_REGISTER_DEVICES_MISSING_REQUIRED_FIELDS: 'Mandatory field {field_name} is missing.',

    // Data
    GROUP_MULTICASTS_MESSAGE: 'There {number, plural, =0{are no multicasts} one{is 1 multicast} other{are # multicasts}} for group: ',
    DATA_DELETE_PACKET: 'Delete Packet',
    DATA_DELETE_PACKETS: 'Delete Packets',

    DIRECTION_SHORT: 'Direction',

    PACKET_STATUS_0: 'Enqueued',
    PACKET_STATUS_1: 'Sent, waiting Ack',
    PACKET_STATUS_1_1: 'Sent',
    PACKET_STATUS_2: 'Ack Ok',
    PACKET_STATUS_3: 'Ack Fail',
    PACKET_STATUS_4: 'Error',
    PACKET_STATUS_5: 'Cancelled',

    TIME: 'Time',
    FCNT: 'FCNT',
    PORT: 'Port',
    DATA_RATE: 'Data Rate',
    DATA: 'Data',
    RSSI: 'RSSI',

    // Send Data Modal

    // Gateway Related
    GATEWAYS_TITLE: 'Gateways',
    GATEWAY_ID: 'Gateway ID',
    GATEWAY_NAME: 'Gateway Name',
    VIEW_GATEWAY: 'Gateway Location',
    DELETE_GATEWAY: 'Delete Gateway',
    DELETE_GATEWAYS: 'Delete Gateways',
    ADD_GATEWAY: 'Add Gateway',

    GATEWAY_STATUS_ANY: 'Any',

    // Modal Add Gateway

    // Map
    MAP_FILTER_DEVICES: 'Filter devices',
    MAP_FILTER_GATEWAYS: 'Filter gateways',
    MAP_FILTERS_OPERATIONAL_STATUS: 'Filter By Health Status',
    MAP_SHOW_DEVICES: 'Show Devices',
    MAP_SHOW_GATEWAYS: 'Show Gateways',
    MAP_MOVABLE: 'Movable',
    MAP_REFRESH_GATEWAYS: "Refresh Gateway",
    MAP_GATEWAY_TABLE_NAME: "Name",
    MAP_GATEWAY_TABLE_ID: "ID",
    MAP_GATEWAY_TABLE_STATUS: "Status",

    MAP_GATEWAY_TABLE_STATUS_LABEL: "Status:",
    MAP_PANEL_TAB_GATEWAYS: "Gateways",
    MAP_PANEL_TAB_DEVICES: "Devices",
    MAP_TOOLTIP_LOCATION_NOT_SET: "Location not set",
    LAST_UPDATE_NOT_SEEN: "Never Seen",

    // Map Info Tooltip
    MAP_INFO_NAME: "Name",
    MAP_INFO_DEVEUI: "DevEUI",
    MAP_INFO_DEVICEUUID: "Device UUID",
    MAP_INFO_DEVICEID: "Device ID",
    MAP_INFO_COMMENT:"Comment",
    MAP_INFO_HEALTH: "Health",
    MAP_INFO_HEALTH_MSG: "Health Msg",
    MAP_INFO_LAST_UPDATE: "Last Update",

    // Position on map modal

    // Gateway Configuration
    GATEWAY_CONFIGURATION: 'Gateway Info',
    GATEWAY_TYPE: 'Gateway type',

    // Customers
    ADD_CUSTOMER: 'Add Customer',
    CUSTOMER: 'Customer',
    EDIT_CUSTOMER: 'Edit Customer',
    ADMINISTRATOR: 'Administrator',

    // Edit Device Modal

    OWNER: 'Owner',

    ADD_DEVICE_BTN: 'Add Device',
    
    DEVICE_BTN: 'Device',
    DEVICE_DATATABLE_VIEW: 'Basic,Detailed,Expanded',
    FUOTA_BTN:'FUOTA Campaign',
    EXPORT_DEVICE_BTN: 'Export',
    EXPORT_DEVICES_BTN_LABEL: 'Export Devices',
    EXPORT_PAYLOADS_BTN_LABEL: 'Export Payloads',
    IMPORT_DEVICES_BTN_LABEL: "Import Devices",
    PROVISION_DEVICES_BTN: 'Provision Devices',
    IMPORT_DEVICE_BTN: 'Import',
    ADD_FUOTA_CAMPAIGN_BTN: 'FUOTA Campaign',

    // HTTP Push config
    HTTP_PUSH_START: 'Start Push',
    HTTP_PUSH_STOP: 'Stop Push',

    // Warnings

    // Add user modal

    // Messages
    MSG_DEVICE_UPDATE_SUCCESS_BODY: 'Device {deveui} successfully updated',
    MSG_DEVICE_UPDATE_FAIL_BODY: 'Failed to update device. "{data}"',
    MSG_GATEWAY_POS_UPDATE_SUCCESS: 'Gateway successfully updated',
    MSG_GATEWAY_POS_UPDATE_FAIL: 'Failed to update position',

    MSG_TOO_MANY_REQUESTS_WAIT: '[[fa-timer]] You have been throttled[[@br]]The request will resume in {seconds} seconds',
    MSG_TOO_MANY_ATTEMPTS_WAIT: '[[fa-timer]] Too many attempts[[@br]]The sign-in will resume in {seconds} seconds',

    // Add User modal

    // Edit User Modal

    GATEWAY: 'Gateway',

    // Manage App Modal

    // Users list
    USER_LIST_REGISTRATION_RIGHTS: 'Registration Rights',
    USER_LIST_DEVICE_COUNT: 'Registered Devices',

    // Organization list
    ORGANIZATION_LIST_ADD_ORGANIZATION: 'Add Organisation',
    ORGANIZATION: 'Organisation',
    ORGANIZATION_LIST_TITLE: 'Organisations',
    EDIT_ORGANIZATION: 'Edit Organisation',

    ADD_LOGIN: 'Add Login',
    ADD_USER: 'Add User',
    EDIT_LOGIN: 'Edit Login',
    LOGIN: 'Login',
    USER: 'User',
    EDIT_USER: 'Edit User',

    // Generic
    DEVEUI: 'DevEUI',
    DEVICE_UUID: "Device UUID",
    COMMENT: 'Comment',
    DESCRIPTION: 'Description',
    OPSTATUSPMESSGAE: 'Health Msg',
    STATUS: 'Status',
    STATE: 'State',
    OPERATIONALHEALTH: 'Health',
    CONNECTIONSTATUS: 'Conn Status',
    LAST_SEEN: 'Last Update',
    REFRESH_LIST: 'Refresh List',
    REFRESH_DEVICE_LIST: 'Refresh Device List',
    STOP_AUTO_REFRESH: 'Stop autorefresh',
    AUTO_REFRESH: 'Autorefresh',
    BLOCKED_GATEWAYS : 'Pending Gateways',
    DEFAULT_GATEWAYS_CONFIG : "Default Gateway LoraWAN radio configurations",
    MANAGE_GATEWAY_AUTHORIZATION: "Manage Gateway Authorization",
    GATEWAY_TAGS : "Gateway Tags",
    DEFAULT_GATEWAY_PARAMS : "Default Gateway Parameters",

    SIGN_IN: 'Sign in',
    SIGN_OUT: 'Sign out',
    USER_ID: 'User ID',
    PASSWORD: 'Password',

    PAYLOADS: 'Payloads',

    VIA: 'via {originalUser}',
    POWERED_BY: 'Powered by',
    WELCOME_RIGHT_MESSAGE: 'PARTNER FOR A SMARTER FUTURE',

    REFRESH: 'Refresh',
    REGISTER: 'Register',
    DELETE: 'Delete',
    UPDATE: 'Update',
    CLEAR: 'Clear',

    GTW_STATUS_NEVER_SEEN: 'Never Seen',
    GTW_STATUS_GTW_INIT: "Registered, never seen",
    GTW_STATUS_OK: 'OK',
    GTW_STATUS_OFF: 'Off',
    GTW_STATUS_BACKHAUL_ISSUE: 'Connection Problem',
    GTW_STATUS_OFF_OR_BACKHAUL_ISSUE: 'Off or Connection Problem',
    GTW_STATUS_RADIO_OFF: 'LoRa Radio Off',

    OK: 'OK',
    CANCEL: 'Cancel',
    YES: 'Yes',
    NO: 'No',
    DECRYPTED: 'Decrypted',
    CLICK_TO_UNLOCK: "Click to unlock",

    // Interface Type Labels
    INTERFACE_TYPE_MQTT: "MQTT",
    INTERFACE_TYPE_HTTP: "HTTP",
    INTERFACE_TYPE_TR069: "TR069",
    INTERFACE_TYPE_EXTMQTT: "EXTMQTT",
    INTERFACE_TYPE_TCP: "TCP",

    NAV_ADMIN_DEVICES: "Find Devices",
    NAV_ADMIN_USERS: "Find Accounts",

    // forgot password functionality
    FORGOT_PASSWORD: "Forgot your password?",
    FORGOT_USERNAME: "Forgot your username?",
    FORGOT_PASSWORD_INSTRUCTIONS: "Please enter your username below and we'll send you instructions to your email how to change your password",
    RETURN_TO_LOGIN: "Return to Log in",
    SET_PASSWORD: "Set password",
    SEND_RESET_PASSWORD_EMAIL: "Send Email",
    RESET_PASSWORD_WELCOME_USER: "Welcome!<br>Please set a password to get started",
    RESET_PASSWORD_MESSAGE: "Please set a new password",
    RESET_PASSWORD_TOO_SHORT: "Too short",
    RESET_PASSWORD_NOT_COMPLEX: "Missing mix case, numbers and/or special characters",
    RESET_PASSWORD_DOSNT_MATCH: "Not matching",
    CONFIRM_PASSWORD: "Confirm Password",

    EMAIL_USERNAME_INSTRUCTIONS_ORBIWISE: "[[fa-envelope]] Password Reset Request Submitted",

    INVALID_TOKEN: "The password reset link has expired, please make a new request for resetting your password",
    PASSWORD_RESET_FAILED: "Unable to reset password",
    PASSWORD_RESET_EMAIL_TOO_MANY: "Too many reset email attempts, please try again later",


    // Email verification
    EMAIL_VERIFICATION_SUCCESSFUL: "Email succesfully verified",
    EMAIL_VERIFICATION_INVALID: "Invalid email verification link",
    EMAIL_VERIFICATION_EXPIRED: "Email verification link no longer valid",

    //password policies

    MAP_NO_POSITION_SET: "No position set",
    BATCH_REGISTER_MAX_DEVICES: "The number of devices is bigger that the allowed number of devices for the user",
    NAV_PROFILES_ITEM: "Profiles",
    NAV_DEVICE_PROFILES: "Device Profiles",
    NAV_SERVICE_PROFILES: "Service Profiles",
    NAV_CONNECTIVITY_PROFILES: "Connectivity Profiles",
    NAV_ROAMING_PROFILES: "Roaming Profiles",

    SERVICE_PROFILE: "Service Profile",

    CONNECTIVITY_PROFILE: "Connectivity Profile",
    LOGIN_FAILED: "Login Failed",
    LOGIN_BLOCKED_DUE_TO_TOO_MANY_ATTEMPTS: "Too many failed signin attempts, signin temporarely suspended. Please try again later.",

    ROAMING_PROFILE: "Roaming Profile",

    ACTION_ADD: "Add Profile",
    CONNECTION_ADD: "Add Connection",
    IMPORT_PROFILE: "Import Profile",
    PROFILE_MSG_DELETE_SUCCESS: "Profile successfully deleted",
    CONNECTION_MSG_DELETE_SUCCESS: "Connection successfully deleted",
    PROFILE_MSG_EXPORT_SUCCESS: "Profile successfully exported",
    CONNECTION_MSG_EXPORT_SUCCESS: "Connection successfully exported",
    PROFILE_MSG_UNLINKED_SUCCESS: "Profile successfully unlinked",
    CONNECTION_MSG_UNLINKED_SUCCESS: "Connection successfully unlinked",
    DEVICE_SERVICE_EXECUTE_SUCCESS: "Service executed successfully!",
    DEVICE_SERVICE_SCHEDULED_SUCCESS: "Service scheduled successfully!",
    DEVICE_SERVICE_EXECUTE_ERROR_LIVELOG: "Service not executed. Please check the live log for more information.",
    DEVICE_SERVICE_EXECUTE_ERROR: "Service execution failed. Error:",
    SERVICE_SCHEDULER : "Schedule Service",

    ACTION_DELETE: "Delete",
    TABLE_PROFILE_NAME: "Profile Name",
    TABLE_CONNECTION_NAME: "Connection Name",
    TABLE_IMAGE: "Image",
    TABLE_COMMENT: "Description",
    TABLE_UUID: "UUID",
    TABLE_LINK: "Link",
    TABLE_REGION: "Region",

    
    TABLE_NAME: "Name",
    TABLE_NST_GATEWAY: "Gateway",
    TABLE_NST_GATEWAY_ID: "Gateway ID",
    TABLE_NST_GATEWAY_HAS_BEEN_SEEN: "Seen",
    TABLE_NST_TENANT: "Tenant",
    TABLE_NST_TENANT_UUID: "Tenant UUID",
    TABLE_NST_NUM_ALARMS: "#Alarms",
    TABLE_NST_GATEWAY_LAST_CONNECTION_TIME: "Last connection time",
    TABLE_NST_GATEWAY_TYPE: "Gateway Type",
    TABLE_NST_GATEWAY_SW_VERSION: "Software Version",
    TABLE_NST_GATEWAY_BACKHAUL: "Backhaul",
    TABLE_NST_GATEWAY_CELLULAR_RSSI: "Cellular RSSI",
    TABLE_NST_GATEWAY_CELLULAR_CONNECTION: "Cellular Connection",
    
    TABLE_NST_DEVICE_COMMENT: "Device comment",
    TABLE_NST_DEVADDR: "DevAddr",
    TABLE_NST_SPREADING_FACTOR: "SF",
    TABLE_NST_LAST_RECEPTION_TIME: "Lst RX",
    TABLE_NST_LAST_RSSI: "Lst RSSI",
    TABLE_NST_LAST_SNR: "Lst SNR",
    TABLE_NST_MAIN_GATEWAY_ID: "Main Gateway",
    TABLE_NST_NUM_OF_GATEWAYS: "#Gtwys",
    TABLE_NST_MAIN_GATEWAY_NAME: "Main Gtwy NM",

    NST_SERVERS_STOP_SERVER: "Stop Server",
    NST_SERVERS_RESTART_SERVER: "(Re)Start Server",

    TABLE_NST_SERVER: "Server",
    TABLE_NST_IP_ADDRESS: "IP",
    TABLE_NST_METRICS: "Metrics",
    TABLE_NST_SERVER_STATUS: "Status",
    TABLE_NST_ALARM_STATUS: "Alarm Status",
    TABLE_NST_SERVER_STATE: "Server State",

    TABLE_NST_ALARM_TYPE: "Alarm Type",
    TABLE_NST_SERVER_ID: "Server ID",
    TABLE_NST_SERVER_TYPE:  "Server Type",
    TABLE_NST_START_DATE: "Start Date",
    TABLE_NST_END_DATE: "End Date",
    TABLE_NST_START_TIME: "Start Time",
    TABLE_NST_JOB_TYPE: "Type",
    TABLE_NST_DESCRIPTION: "Description",
    TABLE_NST_BATCH_ID: "Batch ID",
    TABLE_NST_OWNER: "Owner",


    TABLE_NST_TIME: "Time",
    TABLE_NST_USER: "User",
    TABLE_NST_EVENT: "Event",


    NST_TENANT_CERTIFICATE_ACTION: "Certificate",


    
    DELETE_PROFILE_MODAL_TITLE: "Delete Profile",
    DELETE_CONNECTION_MODAL_TITLE: "Delete Connection",
    DELETE_PROFILE_MESSAGE_MODAL_BODY: "Are you sure you want to delete {ProfileName} profile?",
    DELETE_CONNECTION_MESSAGE_MODAL_BODY: "Are you sure you want to delete {ProfileName} connection?",

    PROFILE_EDIT_ACTION: "Edit Profile",
    PROFILE_DELETE_ACTION: "Delete Profile",
    PROFILE_DELETE_BULK_ACTION: "Delete Profiles",
    CONNECTION_DELETE_ACTION: "Delete Connection",
    CONNECTION_DELETE_BULK_ACTION: "Delete Connections",

    DEVICE_PROFILE: "Device Profile",
    IMPORT_DEVICE_PROFILE: "Import Device Profile",
    IMPORT_CONNECTION_PROFILE: "Import Connection Profile",

    QOS_PROFILE: "QoS Profile",
    NAV_QOS_PROFILES: "QoS Profiles",
    NAV_CHANNEL_PROFILES: "Channel Profiles",
    NAV_CONNECTION_PROFILES: "Connection Profiles",
    NAV_CONNECTIONS: "Connections",
    CHANNEL_PROFILE: "Channel Profile",
    CONNECTION_PROFILE: "Connection Profile",
    CONNECTIONS: "Connections",
    CONNECTION: "Connection",

    SELECT_PROFILE_PLACEHOLDER: "Select profile",

    //Static messages

    BATCH_MUST_BE_A_STRING: " must be a string",
    BATCH_FIELD: "Field",
    BATCH_INVALID_VALUE: "contains an invalid value.",
    BATCH_MUST_8: " must be an 8-byte identifier.",
    BATCH_ERROR_MUST_16BIT: " must be an 16-byte identifier.",
    BATCH_ERROR_MUST_32BIT: " must be an 32-bit identifier.",
    BATCH_VALID_VALIE_BETWEEN: " valid values are between ",
    BATCH_TO: " to",
    BATCH_VALID_IS_NOT_SUPPORTED: " is not supported",
    BATCH_MUST_BE_BOOLEN: " must be boolean",
    BATCH_CAN_ONLY_BE_A_NUMBER: "can only be a number",
    BATCH_ALLOWED_VALUES_FOR: "Allowed values for ",
    BATCH_ALLOWED_VALUES_FOR_IS: " are sf7, sf8, sf9, sf10, sf11 and sf12",
    BATCH_ALLOWED_VALUES_FOR_STATIC: " are static mobile indoor and outdoor or combination from static,indoor static,outdoor mobile,indoor mobile,outdoor",
    BATCH_OTAA_ABP: " can only have values OTAA or ABP",
    BATCH_0_9: " valid values are 0 to 100",
    BATCH_0: " can only be 0",
    BATCH_CAN_ONLY_VERSION: " can only be 1.1.1, 1.0.4, 1.0.3, 1.0.2, 1.0.1 or 1.0.0",
    BATCH_CAN_A_B: " can only be A or B",
    BATCH_CAN_EU_US_CH: " can be EU868, US902, China779, EU433, Australia915, China470, AS923 or INDIA",
    BATCH_ERROR_ROW: " Errors found on row ",
    BATCH_ERROR: " error",
    MAC_MSG: "MAC Messages",

    SHOW_DECODE_PAYLOAD_SHORT: "Decoded",
    SHOW_DATA: "Data",
    SHOW_DMP_DEVICE_MESSAGE: "Message",
    SHOW_DMP_DEVICE_MESSAGE_TYPE: "Message Type",
    SHOW_DMP_DEVICE_TOPIC: "Topic",

    POSITION_ESTIMATES_SHORT: "Pos Est",    
    DUPLICATE_HEADERS: "The csv file contains duplicate headers",

    // Linked Profiles
    NEW_LINKED_PROFILE: "Linked Profile",
    ADD_NEW_LINKED_PROFILE: "Create Linked Profile",
    BATCH_REGISTER_DEVICES_ERROR3: "The csv file must contain the mandatory properties for device with profiles",
    BATCH_WRONG_FORMAT: " format is not correct",
    UNLINK_PROFILE_MESSAGE_MODAL_BODY: "Are you sure you want to unlink {ProfileName} profile?",
    UNLINK_LINKED_PROFILE_MODAL_TITLE: "Unlink Profile",
    UNLINK_LINKED_PROFILE_ACTION: "Unlink Profile",
    UNLINK_PROFILE_MODAL_CONFIRM_BUTTON: "Unlink",

    // API Based Table

    TABLE_SHARED: "Shared",
    TABLE_SORRY_NO_DATA_AVAILABLE: "No data available!",
    TABLE_SHOWING: "Showing {value}",
    TABLE_COUNT_ALL: "All",
    TABLE_COUNT_SELECTED: "Selected",
    TABLE_BUTTON_CLEAR_SELECTED: "Clear",
    TABLE_BUTTON_BULK_ACTIONS: "Bulk Actions",
    TABLE_BUTTON_BULK_SERVICE_ACTIONS: "Services",
    TABLE_CONNECTION_INTERFACE_TYPE: "Interface Type",

    // EMPTY RECORD MSG

    NO_DEVICE_AVAILABLE: "No Device Available",
    NO_GROUP_AVAILABLE: "No Group Available",
    NO_TAG_AVAILABLE: "No Tag Available",
    NO_RESOURCE_AVAILABLE: "No Resource Available",
    NO_APPLICATION_AVAILABLE: "No Application Available",
    NO_PROFILE_AVAILABLE: "No Profile Available",
    NO_SERVICE_AVAILABLE: "No Service Available",
    NO_CONNECTION_AVAILABLE: "No Connection Available",
    NO_JOB_AVAILABLE: "No Job Available",







    // Device Profiles Json Schema Form

    // Connectivity Profiles Json Schema Form

    // Roaming Profiles Json Schema Form

    // QoS Profiles Json Schema Form

    // Channel Profiles Json Schema Form

    TABLE_JOIN_EUI_RANGE: "Join EUI range",
    TABLE_JOIN_SERVER_NAME: "Name",
    TABLE_JOIN_SERVER_TYPE: "Type",
    TABLE_JOIN_SERVER_URL: "URL",

    ADD_JOIN_SERVER: "Join Server",




    FIND: "Find",
    ACCOUNTS: "Accounts",
    NAV_SIGNED_AS: "Signed as",
    ADMIN_TYPE_CUSTOMER: "Customer Administrator",
    ADMIN_TYPE_USER: "User Administrator",
    ADMIN_TYPE_ORGANIZATION: "Organisation Administrator",
    TYPE_CUSTOMER: "Customer",
    TYPE_USER: "User",

    SUSPEND_DEVICES: "Suspend Devices",
    UNSUSPEND_DEVICES: "Unsuspend Devices",

    CHANGE_OWNER: "Change Owner",

    CREATABLE_SELECT_LABEL: "UUID",

    CAN_NOT_RETURN_TO_PARENT: "Return to action failed",

    NO_RIGHTS_TO_SEE_THIS_PAGE: 'The User does not have permission to see this page.',
    ALERT_MODAL_TITLE_TEXT: "Error",

    MANAGE_APPLICATIONS_NAV_TITLE: "Manage Applications",

    ACTIVE_CONNECTIONS_NAV_TITLE: "Active Connections",
    ACTIVE_CONNECTIONS_PAGE_TITLE: "Active Connections",
    ACTIVE_CONNECTIONS_TABLE_COL_TYPE: "Type",
    ACTIVE_CONNECTIONS_TABLE_COL_STATUS: "Status",
    ACTIVE_CONNECTIONS_TABLE_COL_CONN_TIME: "Connection Time",
    ACTIVE_CONNECTIONS_TABLE_COL_APPLICATION: "Application",
    ACTIVE_CONNECTIONS_TABLE_COL_REMOTE_ADDRESS: "Remote Address / Target URL",
    ACTIVE_CONNECTIONS_TABLE_COL_SUBSCRIPTIONS: "Subscriptions",
    ACTIVE_CONNECTIONS_TABLE_COL_ERRORS: "Errors",

    EVENT_LOG_NAV_TITLE: "Event Log",
    EVENT_LOG_PAGE_TITLE: "Event Log",
    EVENT_LOGS_TABLE_COL_TIMESTAMP: "Timestamp",
    EVENT_LOGS_TABLE_COL_EVENT: "Event",
    EVENT_LOGS_TABLE_COL_APPLICATION: "Application",
    EVENT_LOGS_TABLE_COL_TEXT: "Text",

    EVENT_LOGS_TABLE_LOG_LEVEL: "Log Level",


    RE_PUSH_PAYLOAD: "(Re-)Push Payload",

    PRODUCT_LICENSE_TITLE: "Product License",
    LORA_UPLINK_LOGS: "LoRa uplink logs",
    GET_SERVER_LOGS: "Server logs",

    // HELP
    NAV_HELP_GETTING_STARTED: "Getting started",
    NAV_HELP_PAGE: "Documentation",
    NAV_HELP_SWAGGER: "Swagger",
    NAV_HELP_SUPPORT_EMAIL: "Email support",
    COLUMNS: "Columns",

    TENANT_DELETE: "Delete Tenant",
    TENANTS_DELETE: "Delete Tenants",
    TENANT_ADD: "Add Tenant",
    TENANT_TITLE: "Tenant",
    TENANT_CERTIFICATE: "Certificate",
    TENANT_INFO: "Tenant Info",
    SEARCH_MAP: "Search Maps",
    FUOTA_CAMPAIGN: "FUOTA Campaigns",
    CAMPAIGN_NAME: "Name",
    CAMPAIGN_DESCRIPTION: "Description",
    CAMPAIGN_FIRMWARE: "Firmware",
    CAMPAIGN_DEVICES: "Devices",
    CAMPAIGN_GROUPS: "Groups",
    CAMPAIGN_STATUS: "Status",
    CAMPAIGN_TYPE: "Type",
    CAMPAIGN_STAGE: "Stage",
    CAMPAIGN_START_TIME: "Start time",
    CAMPAIGN_UPLOAD_DATE: "Upload Date",
    CAMPAIGN_END_TIME: "End time",
    MY_CAMPAIGNS_ACTION_START: "Campaign Start",
    MY_CAMPAIGNS_ACTION_STOP: "Campaign Stop",
    ADD_FUOTA_BTN: "FUOTA",
    MY_DEVICES_ADD_FUOTA_BTN: "Add to FUOTA campaign",
    FIRMWARE_FILE_SIZE: "File Size (bytes)",

    // Resource Storage
    ADD_RESOURCE: "Add Resource",
    EDIT_RESOURCE: "Edit Resource",
    RESOURCE_TITLE: "Resources",
    RESOURCES_DELETE: "Delete Resources",
    RESOURCE_DELETE: "Delete Resource",
    VIEW_RESOURCE_SCHEMA: "View Resource",
    NAV_RESOURCE_ITEM: "Resources",
    DOWNLOAD_RESOURCE: "Download Resource",

    // Tags
    MY_TAGS: "My tags",
    TAG: "Tag",
    ADD_TAG: "Add Tag",
    EDIT_TAG: "Edit Tag",
    TAGS_DELETE: "Delete Tags",
    TAG_DELETE: "Delete Tag",
    NAV_TAG_ITEM: "Tags",
    NAV_MANAGE_TAGS: "Manage tags",
    TAG_ID: "Tag ID",


    // Named Services

    NAV_NAMED_SERVICE_ITEM: "Named Services",
    ADD_NAMED_SERVICE: "Add Named Service",
    NAMED_SERVICE_DELETE: "Delete Named Service",
    NAMED_SERVICES_DELETE: "Delete Named Services",




    // Livelog
    NAV_LIVELOG_ITEM: "Live Log",
    LIVELOG_NOT_ACTIVE_LETS_START_RECORDING: "Looks like we don't have any logs here yet. Let's get started with the log recording.",
    LIVELOG_IS_ACTIVE_PLEASE_WAIT: "LiveLog is active. Please wait for log events to appear.",
    LIVELOG_START_LOG: "Start Logging",
    LIVELOG_STOP_LOG: "Stop Logging",
    LIVELOG_CLEAR: "Clear",

    // API Documentation
    NAV_API_DOC_ITEM: "API Documentation",




    // ErrorMessage
    ACCESS_DENIED_MESSAGE: "Access Denied: You are not authorized to perform this action.",
    SYSTEM_UNREACHABLE_MESSAGE: "System unreachable",


    // Operational Status Labels
    OPERATIONAL_STATUS_OPTION_NORMAL: "OK",
    OPERATIONAL_STATUS_OPTION_WARNING: "Warning",
    OPERATIONAL_STATUS_OPTION_ERROR: "Error",
    OPERATIONAL_STATUS_OPTION_CRITICAL: "Critical",
    OPERATIONAL_STATUS_OPTION_NEVER_SEEN: "Never Seen",
    OPERATIONAL_STATUS_OPTION_SUSPENDED: "Suspended",
    OPERATIONAL_STATUS_OPTION_CONNECTING: "Connecting",

    // Connection Status Labels
    CONNECTION_STATUS_OPTION_CONNECTED: "Connected",
    CONNECTION_STATUS_OPTION_DISCONNECTED: "Disconnected",
    CONNECTION_STATUS_OPTION_ERROR: "Error",
    CONNECTION_STATUS_OPTION_NEVER_SEEN: "Never Seen",


    // Connection Status Tooltip label
    CONNECTION_STATUS_TOOLTIP_DEVICEID: "Device ID",
    CONNECTION_STATUS_TOOLTIP_STATUS: "Status",
    CONNECTION_STATUS_TOOLTIP_NAME: "Device Name",
    CONNECTION_STATUS_TOOLTIP_LAST_CONNECTED_TIME: "Last Connected Time",
    CONNECTION_STATUS_TOOLTIP_LAST_DISCONNECTED_TIME: "Last Disconnected Time",
    CONNECTION_STATUS_TOOLTIP_REMOTE_IP_ADDRESS: "Remote IP Address",
    CONNECTION_STATUS_TOOLTIP_ERROR_MESSAGE: "Error Message",


    BULK_DEVICE_SUCCESSFUL_SERVICE_MESSAGE: "Bulk device service execution complete: Service executed successfully on devices",
    BULK_GROUP_SUCCESSFUL_SERVICE_MESSAGE: "Group device service execution complete: Service executed successfully on devices",
    BULK_DEVICE_FAILED_SERVICE_MESSAGE: "Bulk device service execution complete: Failed to execute service on devices",
    BULK_GROUP_FAILED_SERVICE_MESSAGE: "Group device service execution complete: Failed to execute service on devices",
    BULK_MESSAGE_IN_BATCH: "in Batch",
    EXECUTE_SERVICE_TITLE: "Execute Service",
    SERVICE_PROGRESS_MESSAGE: "Service Progress: {progress}%, Total Devices: {totalDevices}{batchMessage}",
    BATCH_MESSAGE: ", Batch: {currentBatch}/{totalBatches}",


    EXPORT_PROFILE_IMAGE_NOT_FOUND: "Image Not Found",
    OPERATIONAL_STATUS_MESSAGE_PROFILE_EVENT_HANDLER_FAILED:"Profile event handler code failed to compile.",

    IMPORT_FROM_DASS: "Import from OrbiWAN",
    NO_ORBIWAN_INSTANCE: "You don't have any OrbiWAN instance to import devices from. Please add one in user settings.",

    // Device Import
    DEVICE_IMPORT: "Device Import",
    SET_DEVICE_PROFILE: "Set Device Profile",
    NO_DEVICE_TO_REGISTER: "No device to register. Please set a device profile by selecting devices and using bulk actions.",
    ALL_DEVICES_REGISTERED: "All devices are registered",
    ALL_DEVICES_REGISTERED_ALREADY: "All devices are already registered",
    PROCESS_DASS_DEVICES: "Process Devices",
    DEVICES_REGISTERED: "Devices Registered Successfully",
    DEVICE_REGISTRATION_FAILED: "Device Registration Failed",
    UPLOAD_FILE: "Upload File",
    ERROR_PROCESSING_FILE: "Error processing uploaded file",
    LNS_SERVICE_PROFILE: "LNS Service Profile",
    LNS_DEVICE_PROFILE: "LNS Device Profile",
    PROVISION_TOKEN: "Provision Token",
    IN_DMP: "Registered",
    BULK_ACTIONS: "Bulk Actions",
    RESET: "Reset",
    RESTART: "Restart",
    LORA_DEVICE_CLASS: "Class",
    MISSING_COLUMNS_CSV: "The uploaded csv must have the following columns",

    SELECT_DEVICE_PROFILE: "Select Device Profile",
    SELECT_DEVICE_PROFILE_NOTE: "Select a device profile to register devices. The device profile will be assigned to the all selected devices.",
    SELECT_DEVICE_PROFILE_NOTE_LNS: "Select a device profile to register devices. The device profile will be assigned to the device with valid LNS device profile and service profile.",

    SELECT_CONNECTION: "Select Connection",
    SELECT_CONNECTION_NOTE: "Select a connection to register devices. The connection will be assigned to the all selected devices.",
    SET_CONNECTION: "Set Connection",
    CLOSE: "Close",
    SORRY: "Sorry",
    NO_DATA_AVAILABLE: "No data available",
    ERROR_GETTING_DEVICE_PROFILES: "Error getting device profiles from LNS",
    ERROR_GETTING_SERVICE_PROFILES: "Error getting service profiles from LNS",
    NO_DEVICE_SELECTED: "No device selected",
    ERROR_LOADING_REDOC_API: "[[fa-triangle-exclamation fa-2x]] Error loading the API",
    DELETE_DEVICE_MSG: "Devices created in system and linked with OrbiWAN will be deleted at OrbiWAN as well. Devices imported via CSV and from OrbiWAN will be deleted from the system only and not from OrbiWAN.",
    MISSING_MANDATORY_COLUMNS: "Missing mandatory columns",
    REGISTRATION_STATUS: "Registration Status",
    DEVICE_NAME: "Device Name",
    // Rules"De

    NAV_MANAGE_RULES: 'Rules',
    ADD_RULE: "Add Rule",
    EDIT_RULE: "Rule Info",
    DELETE_RULES: "Delete Rules",
    BULK_DELETE_RULE_SUCCESS_MESSAGE: "Bulk Rule deleted successfully",
    DELETE_RULE_SUCCESS_MESSAGE: "Rule successfully deleted",
    BULK_DELETE_CONFIRM_DIALOAG: "Do you want to delete the selected rule(s)",
    DELETE_CONFIRM_DIALOG: "Do you want to delete this rule",
    RULE_NAME: "Rule Name",
    CONDITION_TYPE: 'Condition Type',
    ALARM_NAME: "Alarm Name",
    IS_ACTIVE: "Is Active",
    DISCONNECTED_TOOLTIP: "Disconnected: Device not connected to server, but may still be active.",


    // Device Jobs Constants

    NAV_MANAGE_JOBS: 'Jobs',
    DELETE_JOBS: "Delete Jobs",
    Device_UUID: "DeviceUUID",
    BULK_DELETE_CONFIRM_DIALOAG_JOBS: "Do you want to delete the selected job(s)",
    DELETE_CONFIRM_DIALOG_JOB: "Do you want to delete this job",
    BULK_DELETE_JOB_SUCCESS_MESSAGE: "Bulk Job deleted successfully",
    DELETE_JOB_SUCCESS_MESSAGE: "Job successfully deleted",
    SERVICE_NAME: "Service Name",
    DEVICE_JOB_TYPE: "Job Type",
    DEVICE_JOB_STATUS: "Job Status",
    DEVICE_JOB_SCHEDULE_STARTTIME: "Start Time",
    DEVICE_JOB_SCHEDULE_ENDTIME: "End Time",
    DEVICE_JOB_SCHEDULE_PERIODICITY: "Periodicity",
    DEVICE_JOB_STATUS_OPTION_SCHEDULED: "Scheduled",
    DEVICE_JOB_STATUS_OPTION_COMPLETED: "Completed",
    DEVICE_JOB_STATUS_OPTION_FAILED: "Failed",
    DEVICE_JOB_STATUS_OPTION_RUNNING: "Running",
    DEVICE_JOB_STATUS_OPTION_TIMEOUT: "Timeout",

};
