import React, { lazy, Suspense } from "react";
import { IUiSchemaElemArgs } from "./SchemaController";
import { registerComponentHandler } from "./SchemaExtensions";


const VisTimeline = lazy(() => import("./VisTimeline"));


const visTimelineExtension = (args: IUiSchemaElemArgs) => {

    const { key, value } = args;
    const { options, items, groups } = value || {};

    return args.embedObject(
        <Suspense key={key} fallback={<div><i className="fa-regular fa-spinner fa-spin"></i></div>}>
            <VisTimeline options={options} items={items} groups={groups}></VisTimeline>
        </Suspense>,
        { isContainer: true }
    );
}


registerComponentHandler("timeline", visTimelineExtension);
